import {Transition} from '@headlessui/react'
import {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import style from './Modal.module.css'
import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'
import FlowyFigure from '../../generics/modalBackgroundIcon/FlowyFigure'
import ModalType from '../../../enums/modalType'
import convertModalTypeToModalTheme from '../../modal/logic/convertModalTypeToModalTheme'

export interface ModalProps {
    readonly icon?: string
    readonly iconColor?: ColorEnum
    readonly iconBackgroundColor?: ColorEnum
    readonly flowyColor: ColorEnum
    readonly children: React.ReactNode
    readonly isOpen: boolean
}

export default function Modal(props: ModalProps) {

    const {t} = useTranslation()

    return (
        <Transition appear show={props.isOpen}>
            <div>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25 z-30"/>
                </Transition.Child>

                <div className="fixed inset-0 w-full z-50">
                    <div className="flex min-h-full items-center justify-center">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className={style.mainContainer + ' md:w-[704px] relative rounded-2xl bg-white shadow-xl'}>
                                <FlowyFigure color={props.flowyColor} />
                                <div className={style.contentContainer}>
                                    {props.icon ? <div className={style.iconContainer}>
                                        <i
                                            className={style.icon + ' ' + props.icon + ' text-xl'}
                                            style={{
                                                color: colorEnumAsCssVar(props.iconColor),
                                                backgroundColor: colorEnumAsCssVar(props.iconBackgroundColor)
                                            }}
                                        ></i>
                                    </div> : null}
                                    <div className="space-y-2">
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Transition>
    )
}
