import CellRendererInterface from './cellRendererInterface';
import FormattedDateLabelModel from '../../../formattedDateContent/logic/models/formattedDateLabelModel';
import FormattedDateLabel from '../../../formattedDateContent/FormattedDateLabel';

export default class FormattedDateCellRenderer implements CellRendererInterface<FormattedDateLabelModel> {
    constructor(
        readonly model: FormattedDateLabelModel) {
    }

    public render(): any {
        return <FormattedDateLabel model={this.model} />
    }
}
