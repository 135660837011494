import CellRendererInterface from '../../table/cellRenderers/cellRendererInterface';
import AddressContentModel from '../components/addressContent/logic/models/addressContentModel';
import AddressContent from '../components/addressContent/AddressContent';

export default class AddressContentCellRenderer implements CellRendererInterface<AddressContentModel> {

    constructor(
        readonly model: AddressContentModel
    ) { }

    public render(): any {
        return <AddressContent model={this.model} />
    }
}
