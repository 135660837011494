import { request } from '../../../utils/api';
import { setNotification } from '../ui/setNotification';

export const createMessage = (token, ids, subject, body) => async (dispatch) => {
    dispatch({ type: 'START_LOADING' });
    request(token).post('/api/mail/send', {
        personalizations: [
            {
                to: [
                    {
                        candidateIds: [...ids],
                    },
                ],
            },
        ],
        subject: subject,
        content: [
            {
                type: 'text/plain',
                value: { body, subject },
            },
        ],
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                dispatch(setNotification({
                    id: Date.now(),
                    message: ids.length > 1 ? 'Berichten verzonden.' : 'Bericht verzonden.',
                    type: 'success'
                }));
            }
        })
        .catch((e) => {
            dispatch({ type: 'STOP_LOADING' });
            console.log(e);
        })
        .finally(() => dispatch({ type: 'STOP_LOADING' }));

};
