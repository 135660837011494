import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setTokenToStore } from '../redux/actions/users/handleAuthToken';

import { useDispatch } from 'react-redux';
import Error from '../components/empty-states/Error';
import Loading from '../components/empty-states/Loading';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { getFeaturesFromGrowthBook } from './api';
import { growthbook } from './analytics';
import useAuth from '../hooks/useAuth';
import ErrorBoundary from './ErrorBoundary';
import ErrorPage from '../pages/error/ErrorPage';

WrapperBase.propTypes = {
    children: PropTypes.any.isRequired,
};

function WrapperBase({ children }) {
    const { isLoading, error, isAuthenticated,
        getAccessTokenSilently, user } = useAuth();

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                if (isAuthenticated) {
                    growthbook.setAttributes({
                        email: user?.email,
                        name: user?.name,
                        email_verified: user?.email_verified,
                        nickname: user?.nickname,
                        ui_env: window?.NODE_ENV,
                        origin: window?.origin,
                        language: window?.frames?.localStorage?.i18nextLng
                    });
                    dispatch(setTokenToStore(token));
                }
            } catch (e) {
                return;
            }
        })();
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        (async () => {
            try {
                const response = await getFeaturesFromGrowthBook();
                const { features } = response.data;
                growthbook.setPayload({features});
            } catch (e) {
                return;
            }
        })();
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <Error
            title={'Er is iets misgegaan'}
            message={error.message} />;
    }
    return <>
        <GrowthBookProvider growthbook={growthbook}>
            <ErrorBoundary fallback={<ErrorPage/>}>
                {children}
            </ErrorBoundary>
        </GrowthBookProvider>
    </>;
}
export default WrapperBase;
