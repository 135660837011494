import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../modal/Modal'
import ArchiveListingModalActionType from './logic/models/archiveListingModalActionType'
import ArchiveListingModalModel from './logic/models/archiveListingModalModel'
import style from './ArchiveListingModal.module.css'

export interface ArchiveModalProps {
    readonly model: ArchiveListingModalModel
    readonly onButtonClick: (listingId: string, actionType: ArchiveListingModalActionType) => void
}

export default function ArchiveListingModal(props: ArchiveModalProps) {

    const { t } = useTranslation()

    const [model, setModel] = useState(props.model)

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    return (
        <Modal
            model={model}
            onButtonClick={(a: ArchiveListingModalActionType) => props.onButtonClick(model.listingId, a)}
        >
            <p className={style.text}>{t('listings.archive.modal.info')}</p>
            <p className={style.text}>{t('listings.archive.modal.info.enrichmentWillBeOff')}</p>
            <p className={style.text}>{t('listings.archive.modal.info.areYouSure')}</p>
        </Modal>
    )
}
