import ColorEnum from '../../../enums/colorEnum'
import Modal from './Modal'

interface WarningModalProps {
    readonly isOpen: boolean
    readonly children: React.ReactNode
}

export default function WarningModal(props: WarningModalProps) {
    return (
        <Modal
            isOpen={props.isOpen}
            icon={'ri-alert-line'}
            iconColor={ColorEnum.Warning800}
            iconBackgroundColor={ColorEnum.Warning100}
            flowyColor={ColorEnum.Warning50}
        >
            {props.children}
        </Modal>
    )
}
