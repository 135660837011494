import CellModel from '../../../../table/logic/models/cellModel';
import FormattedDateLabelModel from '../../../../../formattedDateContent/logic/models/formattedDateLabelModel';
import ListingTableActionType from '../listingTableActionType';

export class DateCellModel extends CellModel<FormattedDateLabelModel, ListingTableActionType> {

    public getActionType(): ListingTableActionType {
        return ListingTableActionType.NoAction
    }
}
