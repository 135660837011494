import api, {request} from '../../../utils/api';
import {START_LOADING, STOP_LOADING} from '../ui/types';

export const getAllListings = (token, params) => async (dispatch) => {
    dispatch({type: START_LOADING});

    // eslint-disable-next-line no-undef
    if (window.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED === 'true') {
        if (params.status === 'archived') {
            params.status = ['archived', 'closed'];
        }
        api.getJsonLd(`api/transactional_listings`, params) // closed too when params.status is 'archived'
            .then((response) => {
                dispatch({
                    type: 'GET_LISTINGS',
                    payload: response,
                });
            })
            .catch((e) => {
                dispatch({type: STOP_LOADING});
                console.log(e);
            })
            .finally(() => dispatch({type: STOP_LOADING}));
    } else {
        request(token)
            .get('/api/lead_listings', {params: params})
            .then((response) => {
                dispatch({
                    type: 'GET_LISTINGS',
                    payload: response.data,
                });
            })
            .catch((e) => {
                dispatch({type: STOP_LOADING});
                console.log(e);
            })
            .finally(() => dispatch({type: STOP_LOADING}));
    }
};
