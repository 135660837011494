import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EnumType from '../../../enums/general/enumType'
import Spinner from '../../spinner/Spinner'
import CheckboxColumnHeader from './components/checkboxColumnHeader/CheckboxColumnHeader'
import CellModel from './logic/models/cellModel'
import TableModel from './logic/models/tableModel'
import TableServiceInterface from './logic/service/tableServiceInterface'
import styles from './Table.module.css'
import PaginationBar from '../pagination/PaginationBar';
import PaginationBarModel from '../pagination/logic/model/paginationBarModel';
import debounce from '../../../utils/debounce';

export interface TableProps<E extends EnumType> {
    readonly model?: TableModel<E>
    readonly service: TableServiceInterface<E>
    readonly onEvent?: (eventType: E, rowId: string, cell: CellModel<any, E>) => void
}

export default function Table<E extends EnumType>(props: TableProps<E>) {

    const {t} = useTranslation()

    const debouncedGetTable = useRef(debounce(getTable))
    const [model, setModel] = useState<TableModel<E>>(props.model && props.model)

    useEffect(() => {
        props.service.getTable(1, 25).then((m) => setModel(m))
    }, [])

    async function handlePaginationBarChange(paginationBarModel: PaginationBarModel): Promise<void> {
        setModel(prev => prev.withPaginationBarModel(paginationBarModel));
        debouncedGetTable.current(paginationBarModel)
    }

    async function getTable(paginationBarModel: PaginationBarModel): Promise<void> {
        const newModel = await props.service.getTable(
            paginationBarModel.value.currentPage,
            paginationBarModel.value.itemsPerPage
        )
        setModel(newModel)
    }

    function handleCellClick(cell: CellModel<any, E>) {
        props.onEvent?.(cell.getActionType(), cell.rowId, cell.model)
    }

    return model ? (<>
        <table className={styles.root}>
            <thead className={styles.headerContainer}>
                <tr>
                    <CheckboxColumnHeader rowSpan={2}/>
                    {model.columnGroups.map((colGroup, i) => {
                        return (
                            <th
                                key={'column_group-' + i}
                                colSpan={colGroup.getAmountOfColumns()}
                                style={{width: TableModel.ratioToPercentage(colGroup.widthRatio)}}
                                rowSpan={1}
                            >
                                {t(colGroup.title)}
                            </th>
                        )
                    })}
                </tr>
                <tr>
                    {model.columnGroups.map(colGroup =>
                        colGroup.columns.map((col, i) =>
                            <th
                                key={'column-' + i}
                                className={styles.subHeader}
                                style={{width: TableModel.ratioToPercentage(colGroup.getRelativeColumnWidthRatio(col))}}
                            >
                                {t(col.title)}
                            </th>)
                    )}
                </tr>
            </thead>
            <tbody className={styles.body}>
                {model.getRows().map((r, i) => {
                    return (
                        <tr key={i} className={styles.row}>
                            <td className={styles.cell}>
                                <input type="checkbox"/>
                            </td>
                            {r.cells.map((cell, i) =>
                                <td
                                    key={i}
                                    onClick={() => handleCellClick(cell)}
                                >
                                    {cell.renderer.render()}
                                </td>)}
                        </tr>)
                })}
            </tbody>
        </table>
        {model.paginationBarModel &&
            <PaginationBar
                model={model.paginationBarModel}
                onItemsPerPageChange={m => handlePaginationBarChange(m)}
                onPageChange={m => handlePaginationBarChange(m)}
            />
        }
    </>) : <Spinner/>
}
