import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {useParams, useSearchParams} from 'react-router-dom'
import { getListingDetails } from '../../../redux/actions/listings/getListingDetails'
import { useSelector, useDispatch } from 'react-redux'
import TopNavigation from '../../../components/navigation/TopNavigation'
import ListingsBucket from '../../../components/tabs/ListingsBucket'
import CandidatesTable from '../../../components/tables/candidatesTable/CandidatesTable'
import PaginationBar from '../../../components/tables/pagination/PaginationBar'
import { changeCandidateStatus } from '../../../redux/actions/candidates/setCandidateStatus'
import ExtraInformationModal from '../../../components/modals/ExtraInformationModal'
import { requestExtraInformation } from '../../../redux/actions/candidates/getExtraInformation'
import { createMessage } from '../../../redux/actions/messages/createMessages'
import { toggleAutomaticEnriching } from '../../../redux/actions/listings/setAutoEnriching'
import AutomaticEnrichingModal from '../../../components/modals/AutomaticEnrichingModal'
import RejectionConfirmationModal from '../../../components/modals/RejectionConfirmationModal'
import { setAllCandidatesStatusById } from '../../../redux/actions/candidates/setAllCandidatesStatusById'
import CandidateDetailsSidePanel from '../../../components/organisms/candidateDetailsSidePanel/CandidateDetailsSidePanel'
import SendMessageModal from '../../../components/modals/SendMessageModal'
import InviteToViewingModal from '../../../components/modals/viewing/InviteToViewingModal'
import PaginationBarModel from '../../../components/tables/pagination/logic/model/paginationBarModel'

import {changeCandidateViewingInvitationStatus} from '../../../redux/actions/candidates/setCandidateViewingInvitationStatus'
import {IfFeatureEnabled} from '@growthbook/growthbook-react'
import ListingDetailsHeader from './listingDetailsHeader/ListingDetailsHeader'
import AddressModel from '../../../models/listing/addressModel'
import ListingPropertiesModel from '../../../components/listings/listingProperties/logics/model/listingPropertiesModel'
import ListingStatusTimerModel from '../../../models/listing/listingStatusTimerModel'
import {getAllCandidates} from '../../../redux/actions/candidates/getCandidates'
import ScreeningModalModel from '../../../components/modals/screeningModal/logic/models/screeningModalModel'
import ScreeningModal from '../../../components/modals/screeningModal/ScreeningModal'
import ScreeningModalService from '../../../services/screeningModalService'
import AssignTenantModalModel from '../../../components/modals/AssignTenantModal/logic/models/AssignTenantModalModel'
import AssignTenantModal from '../../../components/modals/AssignTenantModal/AssignTenantModal'
import AssignTenantModalService from '../../../services/assignTenantModalService';
import styles from './ListingDetails.module.css'
import api from '../../../utils/api'
import useAuth from '../../../hooks/useAuth'
import CreateNoteModal from '../../../components/modals/CreateNoteModal';
import {setCandidateNote} from '../../../redux/actions/candidates/setCandidateNote';
import CandidateFilterStatus from '../../../enums/candidateFilterStatus';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ListingDetails() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [queryParams, setQueryParams] = useSearchParams()
    const status = queryParams.get('status');
    const selectedCandidateId = queryParams.get('selectedCandidateId');
    const { user } = useAuth()

    const {
        details,
        candidates,
        numberOfLeads,
        numberOfLeadsToAssess,
        numberOfSuitableLeads,
        numberOfRejectedLeads,
        numberOfNotSuitableLeads,
        numberOfUnassessedLeads,
        closedAt,
    } = useSelector(state => state.listings.selectedListing)

    const { token } = useSelector((state) => state.users)
    const { isLoading } = useSelector((state) => state.ui)

    const [screeningModalModel, setScreeningModalModel] = useState(ScreeningModalModel.createEmpty())
    const [assignTenantModalModel, setAssignTenantModalModel] = useState(AssignTenantModalModel.createEmpty())
    const [selectedStatus, setSelectedStatus] = isValidStatusSelected() ? useState(status) : useState(CandidateFilterStatus.None)
    const [paginationBarModel, setPaginationBarModel] = useState(new PaginationBarModel({ totalItems: 0, currentPage: 1, itemsPerPage: 25 }))
    const [query, setQuery] = useState('')
    const [income, setIncome] = useState(null)
    const [pets, setPets] = useState(null)
    const [sortType, setSortType] = useState('income.desc')
    const [householdType, setHouseholdType] = useState([])
    const [extraInfoModalOpen, setExtraInfoModalOpen] = useState(false)
    const [isInviteToViewingModalOpen, setInviteToViewingModalOpen] = useState(false)
    const [autoEnrichingModalOpen, setAutoEnrichingModalOpen] = useState(false)
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
    const [isFiltersDropdownOpen, setIsFiltersDropdownOpen] = useState(false)
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false)
    const [isRejectingAll, setIsRejectingAll] = useState(false)
    const [selectedPeople, setSelectedPeople] = useState([])
    const [isNoteModalOpen, toggleNoteModal] = useState(false);

    const screeningModalService = useRef(new ScreeningModalService())
    const assignTenantModalService = useRef(new AssignTenantModalService())

    useEffect(() => {
        if (token && id) {
            fetchListingsDetails()
        }
    }, [token, id])

    useEffect(() => {
        setPaginationBarModel(prev => prev.with({ totalItems: candidates.totalItems }))
    }, [candidates.totalItems])

    useEffect(() => {
        if (sortType && householdType && details.enrichment && paginationBarModel) {
            fetchCandidates()
        }
    }, [sortType, pets, householdType, income, selectedStatus, details.enrichment, query, paginationBarModel])

    const fetchListingsDetails = () => {
        dispatch(getListingDetails(token, id))
    }

    const fetchCandidates = () => {
        const params = generateParams(sortType, query, income, pets, paginationBarModel.value.currentPage, householdType, paginationBarModel.value.itemsPerPage, selectedStatus)
        dispatch(getAllCandidates(token, id, params))
    }

    const selectHousehold = (h) => {
        if (Array.isArray(householdType)) {
            if (householdType.includes(h)) {
                setHouseholdType(householdType.filter(household => household !== h))
            } else {
                setHouseholdType([...householdType, h])
            }
        } else {
            setHouseholdType([h])
        }
    }

    const generateParams = (sortType, query, income, pets, page, householdType, itemsPerPage, selectedStatus) => {
        let urlQueryParams = {}
        const [sortBy, direction] = sortType.split('.')

        if (window.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED === 'true') {

            urlQueryParams = {
                fullName: query,
                pets: pets,
                minimumIncome: income,
                order: sortBy,
                sortDirection: direction,
                page: page,
                itemsPerPage: itemsPerPage,
                pagination: true
            }

            if (selectedStatus) {
                urlQueryParams['status'] = selectedStatus
            }

            if (householdType.length > 0) {
                urlQueryParams['householdType'] = structureHouseholdTypeQueryParamString(householdType)
            }
        }

        return urlQueryParams
    }

    const structureHouseholdTypeQueryParamString = (householdType) => {
        return householdType.join(',')
    }

    const confirmBulkRejection = (isModalOpen, shouldRejectAll) => {
        isModalOpen ? setIsRejectionModalOpen(true) : setIsRejectionModalOpen(false)
        shouldRejectAll ? setIsRejectingAll(true) : setIsRejectingAll(false)
    }

    const handleCandidateStatus = (status, idx, isNotified = false) => {
        const params = generateParams(sortType, query, income, pets, paginationBarModel.value.currentPage, householdType, paginationBarModel.value.itemsPerPage, selectedStatus)
        const ids = selectedPeople.map((person) => person.id)

        if (id) {
            dispatch(changeCandidateStatus(token, [idx], status, id, params, isNotified))
            setSelectedPeople([])
        } else {
            dispatch(changeCandidateStatus(token, ids, status, id, params, isNotified))
            setSelectedPeople([])
        }
        fetchCandidates()
    }

    const handleBulkStatusChange = (transition, isNotified = false) => {
        const ids = selectedPeople.map((person) => person.id)
        if (isRejectingAll) {
            try {
                dispatch(setAllCandidatesStatusById(token, id, selectedStatus, transition, isNotified))
                setIsRejectingAll(false)
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                dispatch(changeCandidateStatus(token, ids, transition, undefined, undefined, isNotified))
                setSelectedPeople([])
            }
            catch (err) {
                console.log(err)
            }
            finally {
                fetchCandidates()
            }
        }
    }

    const handleViewingInvitationStatusChange = (transition, subject, body) => {
        const ids = selectedPeople.map((person) => person.id)
        try {
            dispatch(changeCandidateViewingInvitationStatus(token, ids, transition, subject, body))
            setSelectedPeople([])
        }
        catch (err) {
            console.log(err)
        }
        finally {
            fetchCandidates()
        }
    }

    const handleCreateNote = () => {
        dispatch(setCandidateNote(token, selectedCandidateId, body));
        toggleNoteModal(false);
    };

    const resetFilter = () => {
        setIncome(null)
        setHouseholdType([])
        setPets(null)
        setIsFiltersDropdownOpen(false)
        fetchCandidates()
    }

    const handlePopover = (candidate) => {
        setQueryParams({status: selectedStatus, selectedCandidateId: candidate.id })
    }

    const handleRequestExtraInfo = () => {
        const ids = selectedPeople.map((person) => person.id)
        dispatch(requestExtraInformation(token, ids, user.email))
        setSelectedPeople([])
    }

    const sendMessageToSelectedCandidates = () => {
        const ids = selectedPeople.map((person) => person.id)

        try {
            dispatch(createMessage(token, ids.length === 0 ? [selectedCandidateId] : ids, subject, body))
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsMessageModalOpen(false)
        }
    }

    const handleAutoEnriching = (mode) => {
        const enrichingMode = mode === 'instant' ? 'manual' : 'instant'
        try {
            dispatch(toggleAutomaticEnriching(token, details.listing_id, enrichingMode))
            setAutoEnrichingModalOpen(false)
        }
        catch (err) {
            console.error(err)
        }
    }

    const changeStatusTab = (status) => {
        queryParams.set('status', status)
        setQueryParams(queryParams)
    }

    const handleScreenButtonClick = async (candidate) => {
        const isScreeningActivated = (await api.get('api/is-screening-service-activated-for-my-agency')).isActivated
        const isExtraExplanationShowing = await screeningModalService.current.getIsExtraExplanationShowing()

        if (isScreeningActivated) {
            setScreeningModalModel(() => ScreeningModalModel.createWithActivateCandidateContent(candidate).withIsExtraExplanationShowing(isExtraExplanationShowing))
        } else {
            setScreeningModalModel(() => ScreeningModalModel.createWithScreeningActivationContent(candidate))
        }
    }

    const handleAssignTenantButtonClick = (candidate) => {
        setAssignTenantModalModel(() => AssignTenantModalModel.createWithAssignTenantContent(candidate))
    }

    const handleProfilePopoverCloseButtonClick = () => {
        queryParams.delete('selectedCandidateId')
        setQueryParams(queryParams)
    }

    const isProfilePopoverOpen = () => {
        return !!selectedCandidateId
    }

    useEffect(() => {
        if(!status){
            changeStatusTab(CandidateFilterStatus.None)
        }
        setSelectedPeople([])
        setSelectedStatus(status)

    }, [queryParams.get('status')]);

    function isValidStatusSelected() {
        return status !== null && Object.values(CandidateFilterStatus).includes(status);
    }

    return (
        <div className='h-full w-full'>
            <div className='grid grid-cols-9 gap-0 w-full h-screen'>
                <div className={classNames((isProfilePopoverOpen()) ? 'col-span-6' : 'col-span-9', 'overflow-y-scroll')}>
                    <TopNavigation/>
                    <ListingDetailsHeader
                        addressModel={new AddressModel(details.city, details.house_number, details.street, details.postal_code)}
                        listingProperties={isLoading ? null : new ListingPropertiesModel(details.price, details.surface, details.rooms)}
                        listingStatusTimer={new ListingStatusTimerModel(status, closedAt ? new Date(closedAt) : null)}
                        isAutoEnrichmentEnabled={details.enrichment === 'instant'}
                        onAutoEnrichmentSwitchChange={() => setAutoEnrichingModalOpen(true)}
                    />
                    <main className={styles.mainContainer}>
                        <div className={styles.listingsBucketContainer}>
                            <div className={styles.listingsBucket}>
                                <ListingsBucket
                                    status={selectedStatus}
                                    selectStatus={changeStatusTab}
                                    numberOfLeads={numberOfLeads}
                                    numberOfLeadsToAssess={numberOfLeadsToAssess}
                                    numberOfSuitableLeads={numberOfSuitableLeads}
                                    numberOfUnassessedLeads={numberOfUnassessedLeads}
                                    numberOfRejectedLeads={numberOfRejectedLeads}
                                    numberOfNotSuitableLeads={numberOfNotSuitableLeads}
                                />
                            </div>

                            <CandidatesTable
                                query={query}
                                onScreenButtonClick={(candidate) => handleScreenButtonClick(candidate)}
                                onAssignTenantButtonClick={(candidate) => handleAssignTenantButtonClick(candidate)}
                                setQuery={setQuery}
                                isLoading={isLoading}
                                data={candidates.items}
                                totalItems={candidates.totalItems}
                                selectedPeople={selectedPeople}
                                setSelectedPeople={setSelectedPeople}
                                handleCandidateStatus={handleCandidateStatus}
                                sortType={sortType}
                                setSorting={setSortType}
                                pets={pets}
                                setPets={setPets}
                                income={income}
                                setIncome={setIncome}
                                householdType={householdType}
                                setHouseholdType={setHouseholdType}
                                selectHousehold={selectHousehold}
                                toggleExtraInfoModal={setExtraInfoModalOpen}
                                handleBulkStatusChange={handleBulkStatusChange}
                                extraInfoModalOpen={extraInfoModalOpen}
                                sendMessageToCandidate={sendMessageToSelectedCandidates}
                                subject={subject}
                                body={body}
                                setBody={setBody}
                                setSubject={setSubject}
                                isMessageModalOpen={isMessageModalOpen}
                                toggleMessageModal={setIsMessageModalOpen}
                                resetFilter={resetFilter}
                                isFiltersDropdownOpen={isFiltersDropdownOpen}
                                toggleFiltersDropdown={setIsFiltersDropdownOpen}
                                onSearch={() => setPaginationBarModel(prev => prev.with({currentPage: 1}))}
                                status={selectedStatus}
                                toggleRejectionModal={confirmBulkRejection}
                                handlePopover={handlePopover}
                                isShrunk={isProfilePopoverOpen()}
                                toggleInviteToViewingModal={setInviteToViewingModalOpen}
                            />
                            <PaginationBar
                                model={paginationBarModel}
                                onPageChange={(m) => setPaginationBarModel(m)}
                                onItemsPerPageChange={(m) => setPaginationBarModel(m)}
                            />

                        </div>
                    </main>
                </div>
                {<div className={classNames((isProfilePopoverOpen()) ? 'col-span-3 border-l ' : 'hidden', 'overflow-y-scroll w-full')}>
                    <CandidateDetailsSidePanel
                        candidateId={selectedCandidateId}
                        onCloseButtonClick={() => handleProfilePopoverCloseButtonClick()}
                        onStatusButtonClick={handleCandidateStatus}
                        onMessageSendButtonClick={() => setIsMessageModalOpen(true)}
                        onNoteCreateButtonClick={() => toggleNoteModal(true)}
                    />
                </div>}
            </div>

            <ExtraInformationModal
                handleRequestExtraInfo={handleRequestExtraInfo}
                setSelectedPeople={setSelectedPeople}
                extraInfoModalOpen={extraInfoModalOpen}
                toggleExtraInfoModal={setExtraInfoModalOpen}
                selectedPeople={selectedPeople}
            />
            <AutomaticEnrichingModal
                autoEnrichingModalOpen={autoEnrichingModalOpen}
                handleAutoEnriching={handleAutoEnriching}
                toggleAutoEnrichingModal={setAutoEnrichingModalOpen}
                listing={details}
            />
            <RejectionConfirmationModal
                selectedPeopleAmount={selectedPeople.length}
                isRejectionModalOpen={isRejectionModalOpen}
                toggleRejectionModal={setIsRejectionModalOpen}
                handleBulkStatusChange={handleBulkStatusChange}
                status={selectedStatus}
                isRejectingAll={isRejectingAll}
            />
            <SendMessageModal
                sendMessageToCandidate={sendMessageToSelectedCandidates}
                handleCandidateStatus={() => handleCandidateStatus()}
                subject={subject}
                body={body}
                setBody={setBody}
                setSubject={setSubject}
                isOpen={isMessageModalOpen}
                toggleMessageModal={setIsMessageModalOpen}
                selectedPeople={selectedPeople}
                closeModal={() => setIsMessageModalOpen(false)}
            />

            <CreateNoteModal
                body={body}
                setBody={setBody}
                isOpen={isNoteModalOpen}
                closeModal={toggleNoteModal}
                handleCreateNote={handleCreateNote}
            />

            <ScreeningModal
                model={screeningModalModel}
                service={screeningModalService.current}
                onCloseButtonClick = {() => setScreeningModalModel(prev => prev.withIsOpen(false))}
            />

            <AssignTenantModal
                model={assignTenantModalModel}
                service={assignTenantModalService.current}
            />

            <IfFeatureEnabled feature="invite_to_viewing">
                <InviteToViewingModal
                    listingData={details}
                    isOpen={isInviteToViewingModalOpen}
                    toggleMessageModal={setInviteToViewingModalOpen}
                    selectedPeople={selectedPeople}
                    closeModal={() => setInviteToViewingModalOpen(false)}
                    viewingInvitationStatusHandler={handleViewingInvitationStatusChange}
                />
            </IfFeatureEnabled>
        </div>
    )
}

ListingDetails.propTypes = {
    listingId: PropTypes.string
}

export default ListingDetails
