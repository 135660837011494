import CandidatePlace from '../../enums/candidatePlace'
import CandidateStatus from '../../enums/candidateStatus'
import CandidateModel from './candidateModel'
import CandidateScreeningRequestStatusEnum from '../../enums/candidateScreeningRequestStatusEnum'

interface CandidatePositionItem {
    readonly position: CandidatePlace
    readonly isResolvableFunc: (candidate: CandidateModel, isFeatureToggleScreeningOn: boolean) => boolean
}

export default class CandidatePlaceResolver {

    private static readonly PRIORITIZED_CANDIDATE_PLACES: ReadonlyArray<CandidatePositionItem> = [
        {
            position: CandidatePlace.IsListingTenant,
            isResolvableFunc: (c) => c.isListingTenant
        },
        {
            position: CandidatePlace.Rejected,
            isResolvableFunc: (c) => c.status === CandidateStatus.Rejected
        },
        {
            position: CandidatePlace.OnWaitingList,
            isResolvableFunc: (c) => c.status === CandidateStatus.NotSuitable
        },
        {
            position: CandidatePlace.ScreeningSentWithCoTenants,
            isResolvableFunc: (c, isFeatureToggleScreeningOn) => {
                return (
                    isFeatureToggleScreeningOn &&
                    c.status === CandidateStatus.Suitable &&
                    !c.hasScreeningOutcome() &&
                    c.hasCoTenants &&
                    (c.hasCoTenantsWithInvitedScreeningRequests || (c.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Invited))
                )
            }
        },
        {
            position: CandidatePlace.ScreeningNotCompletedWithCoTenants,
            isResolvableFunc: (c, isFeatureToggleScreeningOn) => {
                return (
                    isFeatureToggleScreeningOn &&
                    c.status === CandidateStatus.Suitable &&
                    c.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Expired &&
                    !c.hasScreeningOutcome() &&
                    c.hasCoTenants
                )
            }
        },
        {
            position: CandidatePlace.ScreeningSent,
            isResolvableFunc: (c, isFeatureToggleScreeningOn) => (
                isFeatureToggleScreeningOn &&
                c.status === CandidateStatus.Suitable &&
                c.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Invited &&
                !c.hasCoTenants
            )
        },
        {
            position: CandidatePlace.ScreeningNotCompleted,
            isResolvableFunc: (c, isFeatureToggleScreeningOn) => (
                isFeatureToggleScreeningOn &&
                c.status === CandidateStatus.Suitable  &&
                c.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Expired &&
                !c.hasCoTenants
            )
        },
        {
            position: CandidatePlace.InvitedToViewing,
            isResolvableFunc: (c) => c.isInvitedToViewingPending()
        },
        {
            position: CandidatePlace.AcceptedInvitationToViewing,
            isResolvableFunc: (c) => c.hasAcceptedInvitationToViewing()
        },
        {
            position: CandidatePlace.DeclinedInvitationToViewing,
            isResolvableFunc: (c) => c.hasDeclinedInvitationToViewing()
        },
        {
            position: CandidatePlace.Approved,
            isResolvableFunc: (c) => c.status === CandidateStatus.Suitable
        },
        {
            position: CandidatePlace.FilledInExtraInfoForm,
            isResolvableFunc: (c) => c.hasFilledInExtraInfoForm()
        },
        {
            position: CandidatePlace.ReceivedExtraInfoForm,
            isResolvableFunc: (c) => c.hasReceivedExtraInfoForm()
        },
        {
            position: CandidatePlace.BeingAssessed,
            isResolvableFunc: (c) => c.status === CandidateStatus.Assessment
        },
        {
            position: CandidatePlace.New,
            isResolvableFunc: (c) => c.status === CandidateStatus.None
        }
    ]

    public resolve(candidate: CandidateModel, isFeatureToggleScreeningOn = false): CandidatePlace {
        const firstResolvableItem = CandidatePlaceResolver.PRIORITIZED_CANDIDATE_PLACES.find(p => p.isResolvableFunc(candidate, isFeatureToggleScreeningOn))
        if (!firstResolvableItem) {
            throw Error('Position can not be resolved!')
        }
        return firstResolvableItem.position
    }
}
