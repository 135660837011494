import styles from './CandidateStatusButton.module.css'
import CandidateStatusButtonModel from './logic/models/candidateStatusButtonModel'

export interface CandidateStatusButtonProps {
    readonly model: CandidateStatusButtonModel
}

export default function CandidateStatusButton(props: CandidateStatusButtonProps) {
    return (
        <a
            type="button"
            className={styles.root}
        >
            <i className={props.model.icon}></i> {props.model.amount}
        </a>

    )
}
