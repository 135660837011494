import CellRendererInterface from '../../table/cellRenderers/cellRendererInterface';
import ArchiveToggleButtonModel from '../../../buttons/archiveToggleButton/logic/models/archiveToggleButtonModel';
import ArchiveToggleButton from '../../../buttons/archiveToggleButton/ArchiveToggleButton';

export default class ArchiveButtonCellRenderer implements CellRendererInterface<ArchiveToggleButtonModel> {

    constructor(
        readonly model: ArchiveToggleButtonModel
    ) { }

    public render(): any {
        return (
            <ArchiveToggleButton
                model={this.model}
                onClick={() => null}
            />
        )
    }
}
