import AddressContentModel from './logic/models/addressContentModel'
import styles from './AddressContent.module.css'
import {useTranslation} from 'react-i18next'
import {useFeature} from '@growthbook/growthbook-react';
import {ListingProperties} from '../../../../listings/listingProperties/ListingProperties';
import Badge_Deprecated from '../../../../generics/badge_deprecated/Badge_Deprecated';

export interface AddressContentProps {
    readonly model: AddressContentModel
}

export default function AddressContent(props: AddressContentProps) {
    const FEATURE_SHOW_TIMER_BADGE = useFeature('show_timer_for_transactional_listing').value
    const {t} = useTranslation()
    const statusBadgeModel = props.model.listingStatusTimer.asBadge()

    return (
        <>
            <div className={styles.addressContainer}>
                <h4 className="text-lg font-bold text-primary">
                    {props.model.address.getAddressWithoutPostalCode()}
                </h4>
            </div>
            {props.model.isInfoVisible() ? props.model.listingProperties.isPriceVisible() &&
                 <ListingProperties model={props.model.listingProperties} /> :
                <p className={styles.notFoundText}>{t('general.dataNotFound')}</p>
            }
            {FEATURE_SHOW_TIMER_BADGE && statusBadgeModel &&
                <Badge_Deprecated model={statusBadgeModel}/>}
        </>
    )
}
