import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'moment/locale/nl-be';
import BadgesList from '../../badges/BadgesList';
import FormError from '../../banners/FormError';
import {VIEWING_INVITATION_STATUS_TRANSITIONS} from '../../../utils/constants';
import Button from '../../buttons/button/Button';
import ButtonActionType from '../../buttons/button/logic/models/buttonActionType';
import { useTranslation } from 'react-i18next';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import TextArea from '../../textArea/TextArea';

function InviteToViewingModal(props) {
    const { isOpen, closeModal, selectedPeople, listingData, viewingInvitationStatusHandler } = props;

    const { t } = useTranslation()

    const formValidationSchema = Yup.object().shape({
        startingTimeslot: Yup.string(),
        endingTimeslot: Yup.string(),
        body: Yup.string().required(),
        subject: Yup.string().required()
    });

    const formik = useFormik({
        initialValues: {
            body: '',
            isListVisible: false,
            subject: generateSubject(listingData?.street, listingData?.house_number),
        },
        enableReinitialize: true,
        validationSchema: formValidationSchema,
        onSubmit: () => {
            if (formik.isValid) {
                viewingInvitationStatusHandler(
                    VIEWING_INVITATION_STATUS_TRANSITIONS.INVITE,
                    formik.values.subject, formik.values.body
                );
                closeModal();
            }
        },
    });

    return (
        <>
            <Transition appear show={isOpen === true} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => closeModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0  w-full flex flex-col items-center justify-center ">
                        <div className="flex flex-row items-center justify-center  text-center w-full h-full max-w-3xl">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-h-[90vh] transform
                                 bg-white text-left align-middle shadow-xl transition-all rounded-2xl">
                                    <form

                                        aria-label="Invite for viewing"
                                        name='inviteForViewingForm'
                                        onSubmit={(e) => e.preventDefault()}
                                    >
                                        <div className='min-w-full'>
                                            <div >
                                                <div className="col-span-6 p-6 sticky top-0 z-50 border-b shadow-sm">
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-2xl font-semibold font-heading 
                                                    leading-6 text-primary">
                                                        Uitnodigen voor bezichtiging
                                                    </Dialog.Title>
                                                </div>

                                                <div className="max-h-[60vh] overflow-y-auto p-6 flex flex-col gap-4">
                                                    <p className="text-gray-500 sm:text-md">
                                                        Typ hieronder het bericht voor de bezichtiging. Onder het bericht worden knoppen geplaatst waarmee de kandidaat de uitnodiging kan accepteren of weigeren.
                                                    </p>


                                                    <BadgesList badges={selectedPeople.map(person => person.firstName)} maxBeforeDropdown={3} resourcePlural='Kandidaten' resourceSingular='Kandidaat' />

                                                    <div>
                                                        <label className="text-gray-500 sm:text-md">
                                                            Onderwerp
                                                        </label>
                                                        <input
                                                            placeholder={generateSubject(listingData?.street, listingData?.house_number)}
                                                            required
                                                            type="text"
                                                            value={formik.values.subject}
                                                            onChange={formik.handleChange}
                                                            name="subject"
                                                            id="subject"
                                                            autoComplete="subject"
                                                            className="flex-1 block w-full min-w-0 rounded-md rounded-r-md sm:text-md border-gray-300 bg-white"
                                                        />
                                                        <FormError message={formik.errors.subject} />
                                                    </div>
                                                    <div
                                                        className=''
                                                        data-testid="leadflow-editor"
                                                    >
                                                        <TextArea
                                                            value={formik.values.body}
                                                            onChange={(e) => formik.setFieldValue('body', e)}
                                                        />
                                                    </div>
                                                    <FormError message={formik.errors.body} />
                                                </div>

                                                <div className='col-span-6 flex flex-row items-end justify-end w-full space-x-2 p-6 sticky bottom-0 border-t shadow-sm'>
                                                    <Button
                                                        title={t('actions.cancel')}
                                                        onClick={() => closeModal()}
                                                    />
                                                    <Button
                                                        buttonType={ButtonType.Primary}
                                                        onClick={formik.handleSubmit}
                                                        buttonActionType={ButtonActionType.Submit}
                                                        title={t('viewing.invitation.send')}
                                                        icon="ri-mail-send-line mr-1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>

    );
}

function generateSubject(listingStreet, listingHouseNumber) {
    return `Bezichtiging ${listingStreet} ${listingHouseNumber}`;
}


InviteToViewingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectedPeople: PropTypes.array,
    listingData: PropTypes.any.isRequired,
    viewingInvitationStatusHandler: PropTypes.func.isRequired,
};

export default InviteToViewingModal;
