import { request } from '../../../utils/api';
import { getListingDetails } from '../listings/getListingDetails';
import { setNotification } from '../ui/setNotification';

export const setAllCandidatesStatusById = (token, listingId, candidateStatus, transition, isNotified) => dispatch => {
    // eslint-disable-next-line no-undef
    if (window.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED === 'true') {
        dispatch({ type: 'START_LOADING' });
        request(token)
            .post(`/api/bulk/transactional_listings/${listingId}/candidates/${candidateStatus}/${transition}`, {
                isNotified: isNotified
            })
            .then(() => dispatch(setNotification({ type: 'success', message: 'Kandidaten status aangepast', id: Date.now() })))
            .then(() => dispatch(getListingDetails(token, listingId, {
                page: 1,
                itemsPerPage: 25,
                pagination: true,
                status: candidateStatus,
            })))
            .catch((e) => {
                dispatch({ type: 'STOP_LOADING' });
                console.log(e);
            })
            .finally(() => dispatch({ type: 'STOP_LOADING' }));
    } else {
        dispatch({ type: 'START_LOADING' });
        request(token).post(`/api/bulk/listings/${listingId}/candidates/${candidateStatus}/${transition}`, {
            isNotified: isNotified
        })
            .then(() => dispatch(setNotification({ type: 'success', message: 'Kandidaten status aangepast', id: Date.now() })))
            .then(() => dispatch(getListingDetails(token, listingId, {
                page: 1,
                itemsPerPage: 25,
                pagination: true,
                status: candidateStatus,
            })))
            .catch((e) => {
                dispatch({ type: 'STOP_LOADING' });
                console.log(e);
            })
            .finally(() => dispatch({ type: 'STOP_LOADING' }));
    }
};
