import CellModel from '../../../../table/logic/models/cellModel';
import CandidateStatusButtonModel from '../../../components/candidateStatusButton/logic/models/candidateStatusButtonModel';
import ListingTableActionType from '../listingTableActionType';
import CellRendererInterface from '../../../../table/cellRenderers/cellRendererInterface';

export class CandidateStatusButtonCellModel extends CellModel<CandidateStatusButtonModel, ListingTableActionType> {
    
    constructor(
        public readonly rowId: string,
        public readonly model: CandidateStatusButtonModel,
        public readonly actionType: ListingTableActionType,
        public readonly renderer: CellRendererInterface<CandidateStatusButtonModel>
    ) {
        super(rowId, model, renderer)
    }

    public getActionType(): ListingTableActionType {
        return this.actionType
    }
}
