import EnumType from '../../../../../enums/general/enumType'
import CellModel from './cellModel'
import TableModel from './tableModel'

export default class ColumnModel<T, E extends EnumType> {
    constructor(
        public readonly title: string,
        public readonly cells: ReadonlyArray<CellModel<T, E>> = [],
        public readonly widthRatio = TableModel.MINIMUM_COLUMN_WIDTH_RATIO
    ) {}
}
