import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import EmailPreviewTabs from '../tabs/EmailPreviewTabs';
import PropTypes from 'prop-types';

function SendMessageModal(props) {
    const { isOpen, closeModal, subject, body, setBody,
        setSubject, sendMessageToCandidate, receiversEmail, selectedPeople } = props;

    const handleCloseModal = () => {
        closeModal();
        setBody('');
        setSubject('');
    };

    return (
        <>
            <Transition appear show={isOpen === true} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => handleCloseModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto w-full flex flex-col items-center justify-center">
                        <div className="flex min-h-full flex-row items-center justify-center p-4 text-center w-full max-w-3xl">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full transform overflow-hidden 
                                rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className='min-w-full p-2'>
                                        <div className="grid grid-cols-6 gap-4">
                                            <div className="col-span-5">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                    Bericht opstellen
                                                </Dialog.Title>
                                            </div>
                                            <div className='flex justify-end flex-row'>
                                                <button
                                                    onClick={() => closeModal(false)}
                                                    type="button"
                                                    className="inline-flex items-center px-1.5 py-1 border 
                                                    border-transparent text-md font-medium rounded text-primary 
                                                    bg-white border-white focus:outline-none"
                                                >
                                                    <i className="ri-close-line ri-lg"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='h-full'>
                                            <EmailPreviewTabs
                                                selectedPeople={selectedPeople}
                                                receiversEmail={receiversEmail}
                                                subject={subject}
                                                body={body}
                                                setBody={setBody}
                                                setSubject={setSubject}
                                                sendMessageToCandidate={sendMessageToCandidate}
                                            />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>

    );
}

SendMessageModal.propTypes = {
    receiversEmail: PropTypes.string,
    setSubject: PropTypes.func.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    setBody: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    sendMessageToCandidate: PropTypes.func.isRequired,
    selectedPeople: PropTypes.array
};

export default SendMessageModal;
