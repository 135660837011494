import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

function CreateNoteModal(props) {
    const { isOpen, closeModal, body, setBody, handleCreateNote } = props;

    return (
        <>
            <Transition appear show={isOpen === true} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => closeModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto w-full">
                        <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className="w-full md:w-[600px] transform overflow-hidden 
                                rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className=" min-w-full p-2">
                                        <div className="grid grid-cols-6 gap-4">
                                            <div className="col-span-5">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-medium leading-6 text-gray-900">
                          Notitie opstellen
                                                </Dialog.Title>
                                            </div>
                                            <div className="flex justify-end flex-row">
                                                <button
                                                    onClick={() => closeModal(false)}
                                                    type="button"
                                                    className="inline-flex items-center px-1.5 py-1 border 
                                                    border-transparent text-md font-medium rounded text-primary 
                                                    bg-white border-white focus:outline-none">
                                                    <i className="ri-close-line ri-lg"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <textarea
                                                rows={5}
                                                value={body}
                                                onChange={(e) => setBody(e.target.value)}
                                                name="comment"
                                                id="comment"
                                                className="shadow-sm block w-full sm:text-md border-gray-300 rounded-md"
                                                placeholder="Schrijf een bericht..."
                                            />
                                        </div>
                                        <div className="mt-4 flex justify-end">
                                            <button
                                                onClick={() => handleCreateNote()}
                                                className="inline-flex items-center justify-center px-3 py-2 
                                            border border-transparent text-md font-medium rounded-md 
                                            shadow-sm text-white bg-primary hover:bg-primary-dark 
                                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                                <i className="ri-add-line ri-lg mr-2"></i> Notitie opslaan
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

CreateNoteModal.propTypes = {
    handleCreateNote: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    body: PropTypes.string.isRequired,
    setBody: PropTypes.func,
};

export default CreateNoteModal;
