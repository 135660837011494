import React from 'react'
import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'
import Button from '../buttons/button/Button'
import ButtonType from '../buttons/button/logic/models/buttonType'
import { useTranslation } from 'react-i18next'
import TextArea from '../textArea/TextArea'

import TextInput from '../forms/textInput/TextInput'

function EmailPreviewTabs(props) {
    const { subject, body,
        setBody,
        setSubject, sendMessageToCandidate,
        receiversEmail, selectedPeople } = props

    const { t } = useTranslation()

    const renderSelectedPeople = (candidates) => {
        if (candidates.length >= 1) {
            return selectedPeople.map((people) => people.email.toLowerCase()).join()
        }
    }

    function isBodyEmpty() {
        return props.body.trim() === '' || new RegExp(/^(<p><br><\/p>)*$/g).test(props.body.trim())
    }

    function isSubjectEmpty() {
        return props.subject.trim() === '' || new RegExp(/^(<p><br><\/p>)*$/g).test(props.subject.trim())
    }


    return (
        <div className='h-full font-body'>
            <div className="mt-2 flex flex-col rounded-md">
                <span className="inline-flex items-center rounded-l-md border border-r-0 text-gray-500 sm:text-md bg-white border-white">
                    Voor:
                </span>
                {selectedPeople ?
                    <>
                        {selectedPeople.length >= 1 ? <div
                            className="block h-auto w-auto min-w-0 rounded-md truncate rounded-r-md w- sm:text-md border-white font-medium">
                            {renderSelectedPeople(selectedPeople)}
                        </div> : <input
                            type="text"
                            value={receiversEmail}
                            name="email"
                            disabled
                            id="email_sender"
                            autoComplete="email"
                            className="flex-1 block w-full min-w-0 rounded-md rounded-r-md sm:text-md border-white  font-medium"
                        />
                        }
                    </> : <input
                        type="text"
                        value={receiversEmail}
                        name="email"
                        disabled
                        id="email_sender"
                        autoComplete="email"
                        className="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-md border-white bg-white"
                    />}
            </div>
            <div className="mt-2 flex rounded-md flex-col">
                <span
                    className="inline-flex  items-center px-0 rounded-l-md border border-r-0
                        text-gray-500 sm:text-md bg-white border-white my-2">
                        Onderwerp
                    <span className='text-red-600 mx-1'>{'(verplicht)'}</span>:
                </span>
                <TextInput
                    placeholder="Onderwerp"
                    type="text"
                    name="subject"
                    id="subject"
                    autoComplete="subject"
                    className="flex-1 block w-full min-w-0 rounded-md rounded-r-md sm:text-md border-gray-300 bg-white"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}/>
            </div>
            <div className="h-auto" id="message-modal">
                <span className="inline-flex  items-center px-0 rounded-l-md border border-r-0
                text-gray-500 sm:text-md bg-white border-white my-2 font-body">
                    Bericht:
                </span>
                <TextArea
                    value={body}
                    onChange={(val) => setBody(val)}
                />
            </div>

            <div className="mt-4 flex justify-end" >
                <Button
                    title={t('candidate.action.sendMessage')}
                    buttonType={ButtonType.Primary}
                    isDisabled={isSubjectEmpty() || isBodyEmpty()}
                    icon='ri-mail-send-line'
                    onClick={sendMessageToCandidate}
                />
            </div>
        </div >
    )
}

EmailPreviewTabs.propTypes = {
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    setBody: PropTypes.func.isRequired,
    setSubject: PropTypes.func.isRequired,
    sendMessageToCandidate: PropTypes.func,
    selectedPeople: PropTypes.array,
    receiversEmail: PropTypes.string,
    'selectedPeople.length': PropTypes.number
}

export default EmailPreviewTabs
