import ArchiveToggleButtonModel from '../../../../../buttons/archiveToggleButton/logic/models/archiveToggleButtonModel';
import ArchiveToggleButtonStatus from '../../../../../buttons/archiveToggleButton/logic/models/archiveToggleButtonStatus';
import CellModel from '../../../../table/logic/models/cellModel';
import ListingTableActionType from '../listingTableActionType';

export class ArchiveToggleButtonCellModel extends CellModel<ArchiveToggleButtonModel, ListingTableActionType> {
    public getActionType(): ListingTableActionType {
        return this.model.status === ArchiveToggleButtonStatus.Archive ?
            ListingTableActionType.ArchiveButtonClick :
            ListingTableActionType.UnarchiveButtonClick
    }
}
