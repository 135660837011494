import Title from '../../../components/typography/title/Title'
import FontSizeEnum from '../../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../../enums/colorEnum'
import FontWeightEnum from '../../../components/typography/enums/fontWeightEnum'
import TopNavigation from '../../../components/navigation/TopNavigation'
import Button from '../../../components/buttons/button/Button'
import Text from '../../../components/typography/text/Text'
import style from './MergeDuplicateTransactionalListingsPage.module.css'
import ButtonType from '../../../components/buttons/button/logic/models/buttonType'
import TextInput from '../../../components/forms/textInput/TextInput'
import {useTranslation} from 'react-i18next'
import ConfirmMergeModal from './components/ConfirmMergeModal'
import {useRef, useState} from 'react'
import MergeDuplicateTransactionalListingsService from './service/mergeDuplicateTransactionalListingsService'
import MergeDuplicateTransactionalListingsPageModel from './model/mergeDuplicateTransactionalListingsPageModel'
import {ListingProperties} from '../../../components/listings/listingProperties/ListingProperties'

export default function MergeDuplicateTransactionalListingsPage() {

    const {t} = useTranslation()

    const service = useRef(new MergeDuplicateTransactionalListingsService())

    const [model, setModel] = useState(MergeDuplicateTransactionalListingsPageModel.create())

    async function handleAddButtonClick() {
        try {
            const address = await service.current.getAddress(model.getIdFromListingInputValue())
            setModel(prev => {
                try {
                    return prev
                        .addAddress(address)
                        .with({ errorMessage: '' })
                } catch(e) {
                    return prev.with({ errorMessage: e.message })
                }
            })
        } catch(e) {
            setModel(prev => prev.with({ errorMessage: e.message }))
        }
    }

    async function handleConfirmMergeModalConfirmButtonClick() {
        try {
            await service.current.mergeListings(
                model.getPrimaryAddress()!.value.listingId,
                model.getRedundantAddress()!.value.listingId
            )
        } catch (e) {
            setModel(prev => prev.with({ isModalShowingErrorMessage: true }))
            return
        }
        setModel(prev => prev.removeRedundatAddress().with({
            isConfirmModalOpen: false,
            isModalShowingErrorMessage: false
        }))
    }

    return (
        <div className={style.root}>
            <TopNavigation />
            <div className={style.content}>
                <Title
                    size={FontSizeEnum.Md}
                    weight={FontWeightEnum.Bold}
                    color={ColorEnum.Gray800}
                >
                    {t('mergeDuplicateTransactionalListingsPage.title')}
                </Title>

                <div className={style.idInputContainer}>
                    <Text
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray700}
                    >
                        {t('mergeDuplicateTransactionalListingsPage.idInput.label')}
                    </Text>

                    <div>
                        <TextInput
                            name="id"
                            value={model.value.listingInputValue}
                            onChange={(e) => setModel(prev => prev.with({ listingInputValue: e.target.value }))}
                            width={350}
                        />
                        {model.isErrorMessageVisible() && <Text
                            size={FontSizeEnum.Md}
                            color={ColorEnum.Error600}
                        >
                            {model.value.errorMessage}
                        </Text>}
                    </div>

                    <Button
                        isDisabled={model.isAddButtonDisabled()}
                        buttonType={ButtonType.Primary}
                        title={t('mergeDuplicateTransactionalListingsPage.idInput.button')}
                        onClick={() => handleAddButtonClick()}
                    />
                </div>

                {model.value.addresses.length > 0 && <table>
                    <thead>
                        <tr>
                            <th className={style.tableHeader}>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('mergeDuplicateTransactionalListingsPage.table.header.address')}
                                </Text>
                            </th>
                            <th className={style.tableHeader}>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('mergeDuplicateTransactionalListingsPage.table.header.id')}
                                </Text>
                            </th>
                            <th className={style.tableHeader}>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('mergeDuplicateTransactionalListingsPage.table.header.action')}
                                </Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.value.addresses.map((address) => {
                            return <tr key={address.value.listingId}>
                                <td>
                                    <div className={style.tableRowText}>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            <a href={address.getLinkToListing()} target="_blank" rel="noreferrer">
                                                {address.value.value}
                                            </a>
                                        </Text>
                                        <ListingProperties model={address.value.listingProperties} />
                                    </div>
                                </td>
                                <td>
                                    <div className={style.tableRowText}>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {address.value.listingId}
                                        </Text>
                                    </div>
                                </td>
                                <td>
                                    <div className={style.tableRowButtons}>
                                        <Button
                                            isDisabled={!model.areListingsMergeable()}
                                            buttonType={ButtonType.Primary}
                                            title={t('mergeDuplicateTransactionalListingsPage.table.button.merge')}
                                            onClick={() => setModel(prev => prev.with({
                                                isConfirmModalOpen: true,
                                                listingIdSelectedAsPrimaryForMerging: address.value.listingId,
                                                isModalShowingErrorMessage: false
                                            }))}
                                        />
                                        <Button
                                            title={t('mergeDuplicateTransactionalListingsPage.table.button.delete')}
                                            onClick={() => setModel(prev => prev.removeAddress(address.value.listingId))}
                                        />
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>}

            </div>

            <ConfirmMergeModal
                isOpen={model.value.isConfirmModalOpen}
                isShowingErrorMessage={model.value.isModalShowingErrorMessage}
                primaryAddress={model.getPrimaryAddress()?.value.value}
                redundantAddress={model.getRedundantAddress()?.value.value}
                onCancel={() => setModel(prev => prev.with({ isConfirmModalOpen: false }))}
                onConfirm={() => handleConfirmMergeModalConfirmButtonClick()}
            />
        </div>
    )
}
