import moment from 'moment'
import ArchiveToggleButtonModel from '../components/buttons/archiveToggleButton/logic/models/archiveToggleButtonModel'
import ArchiveToggleButtonStatus from '../components/buttons/archiveToggleButton/logic/models/archiveToggleButtonStatus'
import AddressContentModel from '../components/tables/listingsTable/components/addressContent/logic/models/addressContentModel'
import CandidateStatusButtonModel from '../components/tables/listingsTable/components/candidateStatusButton/logic/models/candidateStatusButtonModel'
import FormattedDateLabelModel from '../components/formattedDateContent/logic/models/formattedDateLabelModel'
import ListingTableActionType from '../components/tables/listingsTable/logic/models/listingTableActionType'
import { AddressCellModel } from '../components/tables/listingsTable/logic/models/cellModels/addressCellModel'
import { ArchiveToggleButtonCellModel } from '../components/tables/listingsTable/logic/models/cellModels/archiveToggleButtonCellModel'
import { CandidateStatusButtonCellModel } from '../components/tables/listingsTable/logic/models/cellModels/candidateStatusButtonCellModel'
import { DateCellModel } from '../components/tables/listingsTable/logic/models/cellModels/dateCellModel'
import ColumnGroupModel from '../components/tables/table/logic/models/columnGroupModel'
import ColumnModel from '../components/tables/table/logic/models/columnModel'
import TableModel from '../components/tables/table/logic/models/tableModel'
import AddressContentCellRenderer from '../components/tables/listingsTable/cellRenderers/addressContentRenderer';
import FormattedDateCellRenderer from '../components/tables/table/cellRenderers/formattedDateCellRenderer';
import CandidateStatusButtonCellRenderer from '../components/tables/listingsTable/cellRenderers/candidateStatusButtonCellRenderer';
import ArchiveButtonCellRenderer from '../components/tables/listingsTable/cellRenderers/archiveButtonCellRenderer';
import {stringToEnum} from '../utils/enumType';
import ListingStatus from '../enums/listingStatus';
import ListingPropertiesModel from '../components/listings/listingProperties/logics/model/listingPropertiesModel';
import AddressModel from '../models/listing/addressModel';
import ListingStatusTimerModel from '../models/listing/listingStatusTimerModel';
import PaginationBarModel from '../components/tables/pagination/logic/model/paginationBarModel';

export interface ListingJSON {
    readonly id: string
    readonly status: string
    readonly createdAt: string
    readonly updatedAt: string
    readonly agent: string
    readonly street: string
    readonly enrichment: string
    readonly postalCode: string
    readonly houseNumber: string
    readonly city: string
    readonly link: string
    readonly price: number | null
    readonly surface: number | null
    readonly rooms: number | null
    readonly imageUrl: string
    readonly originId: string
    readonly originName: string
    readonly description: string
    readonly onlineSince: string | null
    readonly availableAt: string | null
    readonly availabilityInDays: number
    readonly onlinePeriodInDays: number
    readonly leadsNumber: number
    readonly numberOfLeads: number
    readonly numberOfMessages: number
    readonly numberOfUnassessedLeads: number
    readonly numberOfLeadsToAssess: number
    readonly numberOfUnreadMessages: number
    readonly numberOfRejectedLeads: number
    readonly numberOfNotSuitableLeads: number
    readonly numberOfSuitableLeads: number
    readonly listingAddress: string
    readonly closedAt?: string
}

export default class ListingsTableJsonToTableModelConverter {

    private static readonly HALF_SIZE = 0.5
    private static readonly THIRD_SIZE = 0.33
    private static readonly FIFTH_SIZE = 0.2
    private static ADVERTISEMENT_COLUMN_GROUP_WIDTH = 0.6
    private static CANDIDATES_COLUMN_GROUP_WIDTH = 0.4

    public static convert(obj: ListingJSON[]): TableModel<ListingTableActionType> {
        return new TableModel(
            [
                ListingsTableJsonToTableModelConverter.extractAdvertisementColumnGroupFromJSON(obj),
                ListingsTableJsonToTableModelConverter.extractCandidateColumnGroupFromJSON(obj)
            ]
        )
    }

    private static extractAdvertisementColumnGroupFromJSON(obj: ListingJSON[]): ColumnGroupModel<ListingTableActionType> {
        const addressColumn = new ColumnModel<AddressContentModel, ListingTableActionType>(
            'listings.ads.address',
            obj.map(l => {
                const model = new AddressContentModel(
                    new AddressModel(l.city, l.houseNumber, l.street, l.postalCode),
                    new ListingStatusTimerModel(stringToEnum(l.status, ListingStatus), l.closedAt !== undefined ? new Date(l.closedAt) : null),
                    new ListingPropertiesModel(l.price, l.surface, l.rooms)
                )
                const renderer = new AddressContentCellRenderer(model)
                return new AddressCellModel(l.id, model, renderer)
            }),
            ListingsTableJsonToTableModelConverter.HALF_SIZE
        )

        const creationDateColumn = new ColumnModel<FormattedDateLabelModel, ListingTableActionType>(
            'listings.ads.createdAt',
            obj.map(l => {
                const model = new FormattedDateLabelModel(moment(l.createdAt).isValid() ? new Date(l.createdAt) : null)
                const renderer = new FormattedDateCellRenderer(model)
                return new DateCellModel(l.id, model, renderer)
            }),
            ListingsTableJsonToTableModelConverter.THIRD_SIZE
        )

        const availableDateColumn = new ColumnModel<FormattedDateLabelModel, ListingTableActionType>(
            'listings.ads.availableAt',
            obj.map(l => {
                const model = new FormattedDateLabelModel(moment(l.availableAt).isValid() ? new Date(l.availableAt) : null)
                const renderer = new FormattedDateCellRenderer(model)
                return new DateCellModel(l.id, model, renderer)
            }),
            ListingsTableJsonToTableModelConverter.THIRD_SIZE
        )

        return ColumnGroupModel.createWithTitle(
            'listings.ads',
            [addressColumn, creationDateColumn, availableDateColumn],
            ListingsTableJsonToTableModelConverter.ADVERTISEMENT_COLUMN_GROUP_WIDTH
        )
    }

    private static extractCandidateColumnGroupFromJSON(obj: ListingJSON[]): ColumnGroupModel<ListingTableActionType> {
        const newColumn = new ColumnModel<CandidateStatusButtonModel, ListingTableActionType>(
            'listings.candidates.new',
            obj.map(l => {
                const model = new CandidateStatusButtonModel('ri-user-add-line', l.numberOfUnassessedLeads)
                const renderer = new CandidateStatusButtonCellRenderer(model)
                return new CandidateStatusButtonCellModel(l.id, model, ListingTableActionType.NewCandidateClick, renderer)
            }),
            ListingsTableJsonToTableModelConverter.FIFTH_SIZE
        )
        
        const assessColumn = new ColumnModel<CandidateStatusButtonModel, ListingTableActionType>(
            'listings.candidates.assess',
            obj.map(l => {
                const model = new CandidateStatusButtonModel('ri-user-search-line', l.numberOfLeadsToAssess)
                const renderer = new CandidateStatusButtonCellRenderer(model)
                return new CandidateStatusButtonCellModel(l.id, model, ListingTableActionType.AssessCandidateClick, renderer)
            }),
            ListingsTableJsonToTableModelConverter.FIFTH_SIZE
        )

        const suitableColumn = new ColumnModel<CandidateStatusButtonModel, ListingTableActionType>(
            'listings.candidates.suitable',
            obj.map(l => {
                const model = new CandidateStatusButtonModel('ri-check-line', l.numberOfSuitableLeads)
                const renderer = new CandidateStatusButtonCellRenderer(model)
                return new CandidateStatusButtonCellModel(l.id, model, ListingTableActionType.SuitableCandidateClick, renderer)
            }),
            ListingsTableJsonToTableModelConverter.HALF_SIZE
        )

        const archiveColumn = new ColumnModel<ArchiveToggleButtonModel, ListingTableActionType>(
            '',
            obj.map(l => {
                const model = new ArchiveToggleButtonModel(ArchiveToggleButtonStatus.Archive)
                const renderer = new ArchiveButtonCellRenderer(model)
                return new ArchiveToggleButtonCellModel(l.id, model, renderer)
            }),
            ListingsTableJsonToTableModelConverter.THIRD_SIZE
        )

        return ColumnGroupModel.createWithTitle(
            'listings.candidates',
            [newColumn, assessColumn, suitableColumn, archiveColumn],
            ListingsTableJsonToTableModelConverter.CANDIDATES_COLUMN_GROUP_WIDTH
        )
    }
}
