import React, {useEffect, useRef, useState} from 'react'
import {UsersIcon as UsersOutline} from '@heroicons/react/outline'
import FiltersDropdown from '../../dropdowns/FiltersDropdown'
import PropTypes from 'prop-types'
import BulkActionDropdown from '../../dropdowns/BulkActionDropdown'
import SendMessageModal from '../../modals/SendMessageModal'
import {useFeature} from '@growthbook/growthbook-react'
import LeadflowDropdown from '../../dropdowns/LeadflowDropdown'
import CandidateContactInfo from '../../candidateData/candidateContactInfo/CandidateContactInfo'
import ButtonGroup from '../../buttonGroup/ButtonGroup'
import ButtonGroupModel from '../../buttonGroup/logic/model/buttonGroupModel'
import ActionType from '../../../enums/actionType'
import CandidateModel from '../../../models/candidate/candidateModel'
import CandidateStatus from '../../../enums/candidateStatus'
import CandidateButtonGroupModelFactory from '../../../models/candidate/candidateButtonGroup/candidateButtonGroupModelFactory'
import MenuAlignment from '../../buttonGroup/enums/menuAlignment'
import CandidateActionsButtonGroup from '../../_archivedComponents/_candidateActionsButtonGroup'
import StatusButtonGroup from '../../_archivedComponents/_statusButtonGroup'
import CandidateJsonToModelConverter from '../../../converters/candidateModelToJsonConverter'
import Spinner from '../../spinner/Spinner'
import FormattedDateLabel from '../../formattedDateContent/FormattedDateLabel'
import FormattedDateLabelModel from '../../formattedDateContent/logic/models/formattedDateLabelModel'
import CandidateSituationProfile from '../../candidateData/candidateProfileInfo/CandidateSituationProfile'
import CandidateSituationProfileModel
    from '../../candidateData/candidateProfileInfo/logic/models/candidateSituationProfileModel'
import SearchBar from '../../searchBar/SearchBar'
import CandidateSituationProfileArrangementEnum
    from '../../candidateData/candidateProfileInfo/logic/models/candidateSituationProfileArrangementEnum'
import styles from './CandidatesTable.module.css'
import {useTranslation} from 'react-i18next'
import Text from '../../typography/text/Text'
import fontSizeEnum from '../../typography/enums/fontSizeEnum'
import fontWeightEnum from '../../typography/enums/fontWeightEnum'
import colorEnum from '../../../enums/colorEnum'
import {useSelector} from 'react-redux'
import Checkbox from '../../forms/checkbox/Checkbox'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import ColorEnum from '../../../enums/colorEnum'
import FontWeightEnum from '../../typography/enums/fontWeightEnum'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * @deprecated use {@link src/components/tables/CandidatesTable.tsx} instead.
 */
function CandidatesTable(props) {
    const {
        data, query, setQuery, onSearch,
        selectedPeople, setSelectedPeople, status,
        handleCandidateStatus, sortType, setSorting,
        pets, setPets, income, setIncome, householdType,
        setHouseholdType, selectHousehold,
        toggleExtraInfoModal, isLoading, handleBulkStatusChange,
        subject, body, setBody, resetFilter,
        setSubject, sendMessageToCandidate, isMessageModalOpen, toggleMessageModal,
        toggleFiltersDropdown, isFiltersDropdownOpen, toggleRejectionModal, handlePopover,
        toggleInviteToViewingModal, onScreenButtonClick, onAssignTenantButtonClick
    } = props
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)

    const {t} = useTranslation()

    const SELECTED_LISTING_HAS_TENANT = !!useSelector((state: any) => state.listings.selectedListing.hasTenant)

    const FEATURE_FLAG_SORT_CANDIDATES_ON_DATE = useFeature('sort_candidates_on_date').value
    const FEATURE_FLAG_INVITE_TO_VIEWING = useFeature('invite_to_viewing').value
    const FEATURE_FLAG_SHOW_NEW_BUTTON_GROUPS = useFeature('show_new_button_groups').value
    const FEATURE_FLAG_REJECT_WITHOUT_MESSAGE = useFeature('reject_without_message').value
    const FEATURE_FLAG_PROPERIZE_INTEGRATION = useFeature('properize-integration').value
    const FEATURE_FLAG_SHRINKING_CANDIDATES_TABLE = useFeature('shrinking-candidates-table').value

    const CANDIDATE_SORTING_OPTIONS = [
        {
            label: 'Inkomen (hoog-laag)',
            value: 'income.desc',
            hidden: false
        },
        {
            label: 'Inkomen (laag-hoog)',
            value: 'income.asc',
            hidden: false
        },
        {
            label: 'Datum aanvraag (oud-nieuw)',
            value: 'createdAt.asc',
            hidden: !FEATURE_FLAG_SORT_CANDIDATES_ON_DATE
        },
        {
            label: 'Datum aanvraag (nieuw-oud)',
            value: 'createdAt.desc',
            hidden: !FEATURE_FLAG_SORT_CANDIDATES_ON_DATE
        }
    ]

    const [candidates, setCandidates] = useState([])

    useEffect(() => {
        setCandidates(data.map(i => CandidateJsonToModelConverter.convert(i)))
    }, [data])

    useEffect(() => {
        if (selectedPeople.length === 0) {
            setChecked(false)
        }
    }, [selectedPeople])

    function toggleAll() {
        setSelectedPeople(checked || indeterminate ? [] : candidates)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    function createModelForButtonGroup(candidate: CandidateModel): ButtonGroupModel {
        return new
        CandidateButtonGroupModelFactory(
            SELECTED_LISTING_HAS_TENANT,
            FEATURE_FLAG_REJECT_WITHOUT_MESSAGE,
            FEATURE_FLAG_PROPERIZE_INTEGRATION
        ).createFor(candidate)
    }

    function handleButtonGroupClick(candidate: CandidateModel, actionType: ActionType): void {
        switch (actionType) {
            case ActionType.Approve:
                return approve(candidate)
            case ActionType.Invite:
                return invite(candidate)
            case ActionType.MoveToWaitingList:
                return moveToWaitingList(candidate)
            case ActionType.Reject:
                return rejectWithMessage(candidate)
            case ActionType.RejectWithoutMessage:
                return rejectWithoutMessage(candidate)
            case ActionType.SendMessage:
                return sendMessage(candidate)
            case ActionType.RequestExtraInfo:
                return requestExtraInformation(candidate)
            case ActionType.Screen:
                return onScreenButtonClick(candidate)
            case ActionType.AssignTenant:
                return onAssignTenantButtonClick(candidate)
            default:
                return
        }
    }

    function approve(candidate: CandidateModel) {
        handleCandidateStatus((candidate.status === CandidateStatus.Suitable ? 'reset' : 'promote'), candidate.id)
    }

    function moveToWaitingList(candidate: CandidateModel): void {
        handleCandidateStatus((candidate.status === CandidateStatus.NotSuitable ? 'reset' : 'devote'), candidate.id)
    }

    function invite(candidate: CandidateModel): void {
        setSelectedPeople([candidate])
        toggleInviteToViewingModal(true)
    }

    function sendMessage(candidate: CandidateModel): void {
        setSelectedPeople([candidate])
        toggleMessageModal(true)
    }

    function rejectWithMessage(candidate: CandidateModel): void {
        handleCandidateStatus(candidate.status === CandidateStatus.Rejected ? 'reset' : 'reject', candidate.id, true)
    }

    function rejectWithoutMessage(candidate: CandidateModel): void {
        handleCandidateStatus(candidate.status === CandidateStatus.Rejected ? 'reset' : 'reject', candidate.id, false)
    }

    function requestExtraInformation(candidate: CandidateModel): void {
        setSelectedPeople([candidate])
        toggleExtraInfoModal(true)
    }

    function isButtonGroupDisabled(candidate: CandidateModel): boolean {
        return selectedPeople.length >= 2 && selectedPeople.includes(candidate)
    }

    function showLegacyButtonGroup(candidate: CandidateModel, index: number) {
        return FEATURE_FLAG_INVITE_TO_VIEWING ?
            <>
                {candidate.status === CandidateStatus.Suitable ? <CandidateActionsButtonGroup
                    candidate={candidate}
                    updateCandidateStatus={handleCandidateStatus}
                    setSelectedPeople={setSelectedPeople}
                    toggleMessageModal={toggleMessageModal}
                    toggleInviteToViewingModal={toggleInviteToViewingModal}
                    isDisabled={selectedPeople.length >= 2 && selectedPeople.includes(candidate)}
                    dropsUp={index + 1 === candidates.length}/>

                    :
                    <StatusButtonGroup
                        handleCandidateStatus={handleCandidateStatus}
                        id={candidate.id}
                        status={candidate.status}
                        hidden={selectedPeople.length >= 2 && selectedPeople.includes(candidate)}/>}
            </> :
            <StatusButtonGroup
                handleCandidateStatus={handleCandidateStatus}
                id={candidate.id}
                status={candidate.status}
                hidden={selectedPeople.length >= 2 && selectedPeople.includes(candidate)}/>
    }

    return (
        <>
            <div className="sm:col-span-1 flex space-x-[8px] items-center py-4 right-0">
                <BulkActionDropdown
                    selectedPeople={selectedPeople}
                    type="candidates"
                    hidden={selectedPeople.length <= 1}
                    toggleExtraInfoModal={toggleExtraInfoModal}
                    handleBulkStatusChange={handleBulkStatusChange}
                    toggleMessageModal={toggleMessageModal}
                    isMessageModalOpen={isMessageModalOpen}
                    candidateStatus={status}
                    toggleRejectionModal={toggleRejectionModal}
                    toggleInviteToViewingModal={toggleInviteToViewingModal}
                    allItems={props.totalItems}
                />
                <FiltersDropdown
                    isFiltersDropdownOpen={isFiltersDropdownOpen}
                    toggleFiltersDropdown={toggleFiltersDropdown}
                    pets={pets}
                    setPets={setPets}
                    income={income}
                    setIncome={setIncome}
                    householdType={householdType}
                    setHouseholdType={setHouseholdType}
                    selectHousehold={selectHousehold}
                    resetFilter={resetFilter}
                />
                <div
                    className="flex flex-col sm:flex-row sm:col-span-2 w-full gap-[8px] items-center justify-end">
                    <SearchBar
                        onChange={(val) => {
                            setQuery(val)
                            onSearch(val)
                        }}
                        value={query}
                    />
                    <LeadflowDropdown
                        options={CANDIDATE_SORTING_OPTIONS}
                        onChange={setSorting}
                        value={sortType}
                        position="right"
                    />
                </div>
            </div>
            {candidates.length > 0 ? <>
                <table
                    className="table-auto divide-y divide-gray-200 mx-4 sm:mx-0  w-full">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr>
                            <th className={styles.checkboxContainer}>
                                <Checkbox
                                    isChecked={checked}
                                    onChange={toggleAll}
                                />
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('candidate.table.title.candidate')}
                                </Text>
                            </th>
                            <th>
                                {props.isShrunk && FEATURE_FLAG_SHRINKING_CANDIDATES_TABLE ?
                                    null :
                                    <Text
                                        size={fontSizeEnum.Xsm}
                                        color={colorEnum.Gray500}
                                        weight={fontWeightEnum.Medium}
                                    >
                                        {t('candidate.table.title.reacted')}
                                    </Text>
                                }
                            </th>
                            <th className="whitespace-nowrap">
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('candidate.table.title.extra.information')}
                                </Text>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    {isLoading ?
                        <tbody className="w-screen text-lg">
                            <tr>
                                <td>
                                    <Spinner/>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {candidates.map((candidate: CandidateModel, index: number) => (
                                <tr
                                    key={candidate.id}
                                    onClick={() => handlePopover(candidate)}
                                    style={{ cursor: 'pointer' }}
                                    className={`
                                                        ${styles.candidateDataRowContainer}
                                                        ${(selectedPeople.includes(candidate) ? 'bg-gray-50' : 'hover:bg-gray-50 hover:shadow-sm')}


                                                    `}
                                >
                                    <td className={`
                                                        ${styles.checkboxContainer}
                                                        ${'border-l-2 border-solid ' +
                                                (selectedPeople.includes(candidate) ? 'border-primary' : 'border-transparent')}`}
                                    >
                                        <Checkbox
                                            isChecked={selectedPeople.includes(candidate)}
                                            value={candidate.id}
                                            onChange={e =>
                                                setSelectedPeople(
                                                    e.target.checked
                                                        ? [...selectedPeople, candidate]
                                                        : selectedPeople.filter(p => p !== candidate)
                                                )
                                            }
                                        />
                                    </td>
                                    <td
                                        className={`${styles.candidateDetailsContainer} ${classNames(
                                            'whitespace-nowrap py-4  hover:cursor-pointer',
                                            selectedPeople.includes(candidate)
                                                ? 'text-primary'
                                                : 'text-gray-900'
                                        )}
                                                        `}
                                    >
                                        <div>
                                            <CandidateContactInfo model={candidate}/>
                                        </div>
                                    </td>
                                    <td className={styles.date}>
                                        <div className='hover:cursor-pointer' onClick={() => handlePopover(candidate)}>
                                            {props.isShrunk && FEATURE_FLAG_SHRINKING_CANDIDATES_TABLE ?
                                                null :
                                                <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                                    <FormattedDateLabel
                                                        model={new FormattedDateLabelModel(new Date(candidate.createdAt))}
                                                    />
                                                </Text>
                                            }
                                        </div>
                                    </td>
                                    <td className={styles.candidateSituationContainer}>
                                        <div className='hover:cursor-pointer py-5' onClick={() => handlePopover(candidate)}>
                                            <CandidateSituationProfile
                                                model={CandidateSituationProfileModel.createFromCandidate((candidate))}
                                                arrangement={(props.isShrunk && FEATURE_FLAG_SHRINKING_CANDIDATES_TABLE ? CandidateSituationProfileArrangementEnum.TwoColumns : CandidateSituationProfileArrangementEnum.ThreeColumns)}
                                            />
                                        </div>
                                    </td>
                                    <td className={styles.buttonContainer}>
                                        <div className='flex justify-end' onClick={e => e.stopPropagation()}>
                                            {FEATURE_FLAG_SHOW_NEW_BUTTON_GROUPS ?
                                                <ButtonGroup
                                                    model={createModelForButtonGroup(candidate)}
                                                    isDisabled={isButtonGroupDisabled(candidate)}
                                                    menuAlignment={index === candidates.length - 1 ? MenuAlignment.Above : MenuAlignment.Below}
                                                    onClick={(a) => handleButtonGroupClick(candidate, a)}
                                                /> :
                                                showLegacyButtonGroup(candidate, index)
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
            </> : <>
                <div className="flex flex-col items-center justify-center h-64">
                    <div className="text-center w-full flex justify-center items-center flex-col">
                        <UsersOutline className="h-8 w-8 text-gray-500"/>
                        <h3 className="mt-2 text-sm font-medium text-gray-500">Geen kandidaten gevonden</h3>
                        <div className="mt-6"></div>
                    </div>
                </div>
            </>}

            <SendMessageModal
                sendMessageToCandidate={sendMessageToCandidate}
                subject={subject}
                body={body}
                setBody={setBody}
                setSubject={setSubject}
                isOpen={isMessageModalOpen}
                selectedPeople={selectedPeople}
                closeModal={() => toggleMessageModal(false)}
            />

        </>
    )
}

CandidatesTable.propTypes = {
    data: PropTypes.array,
    totalItems: PropTypes.number.isRequired,
    handleCandidateStatus: PropTypes.func.isRequired,
    selectedPeople: PropTypes.array.isRequired,
    setSelectedPeople: PropTypes.func.isRequired,
    income: PropTypes.any,
    setIncome: PropTypes.func.isRequired,
    householdType: PropTypes.array.isRequired,
    setHouseholdType: PropTypes.func.isRequired,
    pets: PropTypes.any,
    setPets: PropTypes.func.isRequired,
    selectHousehold: PropTypes.func.isRequired,
    toggleExtraInfoModal: PropTypes.func.isRequired,
    handleBulkStatusChange: PropTypes.func.isRequired,
    sendMessageToCandidate: PropTypes.func.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    setBody: PropTypes.func.isRequired,
    setSubject: PropTypes.func.isRequired,
    isMessageModalOpen: PropTypes.bool.isRequired,
    toggleMessageModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    setSorting: PropTypes.func,
    isExtraInfoModalOpen: PropTypes.bool,
    isOpen: PropTypes.bool,
    isShrunk: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
    sortType: PropTypes.string,
    resetFilter: PropTypes.func,
    isFiltersDropdownOpen: PropTypes.bool.isRequired,
    toggleFiltersDropdown: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onScreenButtonClick: PropTypes.func.isRequired,
    onAssignTenantButtonClick: PropTypes.func.isRequired,
    status: PropTypes.string,
    handlePopover: PropTypes.func.isRequired,
    toggleRejectionModal: PropTypes.func.isRequired,
    toggleInviteToViewingModal: PropTypes.func.isRequired,
}

export default CandidatesTable
