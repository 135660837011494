enum ListingTableActionType {
    AddressClick = 'AddressClick',
    NewCandidateClick = 'NewCandidateClick',
    AssessCandidateClick = 'AssessCandidateClick',
    SuitableCandidateClick = 'SuitableCandidateClick',
    ArchiveButtonClick = 'ArchiveButtonClick',
    UnarchiveButtonClick = 'UnarchiveButtonClick',
    NoAction = 'NoAction'
}

export default ListingTableActionType
