import EnumType from '../../../../../enums/general/enumType'
import ColumnGroupModel from './columnGroupModel'
import CellModel from './cellModel'
import RowModel from './rowModel'
import PaginationBarModel from '../../../pagination/logic/model/paginationBarModel';

export default class TableModel<E extends EnumType> {

    public static readonly MINIMUM_COLUMN_WIDTH_RATIO = 0

    constructor(
        public readonly columnGroups: ReadonlyArray<ColumnGroupModel<E>>,
        public readonly paginationBarModel?: PaginationBarModel
    ) { }

    public withPaginationBarModel(paginationBarModel: PaginationBarModel): TableModel<E> {
        return new TableModel(this.columnGroups, paginationBarModel)
    }

    public getAmountOfColumnGroups(): number {
        return this.columnGroups.length
    }

    public getRows(): RowModel[] {
        const firstColumnGroup = this.columnGroups[0]
        if (this.getAmountOfColumnGroups() === 0 || firstColumnGroup.getAmountOfColumns() === 0) {
            return []
        }
        return firstColumnGroup.columns[0].cells.map((_, i) => new RowModel(this.collectCellsOfAllColumnGroups(i)))
    }

    private collectCellsOfAllColumnGroups(rowIndex: number): CellModel<any, E>[] {
        return this.columnGroups.reduce(
            (prev, curr) => [...prev, ...curr.getRows()[rowIndex].cells],
            [] as CellModel<any, E>[]
        )
    }

    public static ratioToPercentage(ratio: number): string {
        return `${ratio * 100}%`
    }
}
