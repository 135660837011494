import Modal from '../../modal/Modal'
import {useEffect, useState} from 'react'
import AssignTenantModalModel from './logic/models/AssignTenantModalModel'
import AssignTenantModalActionType from './logic/models/assignTenantModalActionType'
import AssignTenantModalFragmentEnum from './logic/models/AssignTenantModalFragmentEnum'
import AssignTenantFragment from './fragments/AssignTenantFragment'
import AssignTenantModalServiceInterface from './service/assignTenantModalServiceInterface'

export interface AssignTenantModalProps {
    readonly model: AssignTenantModalModel
    readonly service: AssignTenantModalServiceInterface
}

export default function AssignTenantModal(props: AssignTenantModalProps) {

    const [model, setModel] = useState(props.model ? props.model : AssignTenantModalModel.createWithAssignTenantContent(props.model.candidate))

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    async function handleModalButtonClick(actionType: AssignTenantModalActionType) {
        if (actionType === AssignTenantModalActionType.Cancel) {
            setModel(prev => prev.withIsOpen(false))
        }

        if (actionType === AssignTenantModalActionType.Assign) {
            setModel(prev => prev.withIsLoading(true))
            await props.service.assignCandidateAsTenant(model.candidate.id)
            setModel(prev => prev.withIsOpen(false))
        }

        if (actionType === AssignTenantModalActionType.AssignAndArchive) {
            setModel(prev => prev.withIsLoading(true))
            await props.service.assignCandidateAsTenant(model.candidate.id, true)
            setModel(prev => prev.withIsOpen(false))
        }
    }

    function handleArchiveCheckboxChange(checked: boolean) {
        setModel(prev => checked ? prev.enableAssignAndArchiveButton() : prev.disableAssignAndArchiveButton())
    }

    return (
        <Modal
            model={model}
            onButtonClick={(actionType: AssignTenantModalActionType) => handleModalButtonClick(actionType)}
        >
            {model.candidate?.email && model.currentFragment === AssignTenantModalFragmentEnum.AssignTenant &&
                <AssignTenantFragment
                    name={model.candidate.firstName}
                    onArchiveCheckboxChange={(val) => handleArchiveCheckboxChange(val)}
                />
            }
        </Modal>
    )
}
