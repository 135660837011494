import CandidateModel from '../../../../models/candidate/candidateModel'
import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import CandidateScreeningRequestStatusEnum from '../../../../enums/candidateScreeningRequestStatusEnum'
import CandidateScreeningRatingBadgeModel
    from '../../../molecules/candidateScreeningRatingBadge/models/candidateScreeningRatingBadgeModel'
import CandidateSituationProfileModel
    from '../../../candidateData/candidateProfileInfo/logic/models/candidateSituationProfileModel'
import ScreeningRequestModel from '../../../../models/screeningRequest/screeningRequestModel'
import CoTenantModel from '../../../../models/screeningRequest/coTenantModel'

interface CandidateDetailsSidePanelModelProps {
    readonly candidateModel?: CandidateModel
    readonly screeningRequestModel?: ScreeningRequestModel
    readonly isDownloadDocumentsLoading: boolean
    readonly isDownloadReportLoading: boolean
    readonly isCandidateLoading: boolean
}

export default class CandidateDetailsSidePanelModel extends ImmutableModel<CandidateDetailsSidePanelModel, CandidateDetailsSidePanelModelProps> {

    public static createEmpty(): CandidateDetailsSidePanelModel {
        return new CandidateDetailsSidePanelModel({
            isDownloadDocumentsLoading: false,
            isDownloadReportLoading: false,
            isCandidateLoading: true
        })
    }

    public isScreeningSectionVisible(): boolean {
        return this.value.candidateModel.screeningRequestStatus !== null
    }

    public isScreeningReportDownloadLinkVisible(): boolean {
        return this.value.candidateModel.screeningRequestId != null && (this.value.candidateModel.hasDoneScreening())
    }

    public isScreeningDocumentsDownloadLinkVisible(): boolean {
        return this.value.candidateModel.screeningRequestId !== null && (this.value.candidateModel.hasDoneScreening())
    }

    public getCandidateScreeningRatingBadgeModel(): CandidateScreeningRatingBadgeModel {
        return new CandidateScreeningRatingBadgeModel(
            this.value.candidateModel.screeningRequestRating,
            this.value.candidateModel.screeningRequestOutcome
        )
    }

    public getCandidateSituationProfileModel(): CandidateSituationProfileModel {
        return CandidateSituationProfileModel.createFromCandidate(this.value.candidateModel)
    }

    public getCoTenantScreeningRequests(): CoTenantModel[] {
        return [...this.value.screeningRequestModel.value.coTenants]
    }
}
