import CandidateStatus from '../enums/candidateStatus'
import CandidateViewingStatus from '../enums/candidateViewingStatus'
import CandidateModel from '../models/candidate/candidateModel'
import { stringToEnum } from '../utils/enumType'
import CandidateScreeningRequestStatusEnum from '../enums/candidateScreeningRequestStatusEnum';
import CandidateScreeningRequestOutcomeEnum from '../enums/candidateScreeningRequestOutcomeEnum'

export interface CandidateJSON {
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly viewingInvitationStatus: string,
    readonly invitedForViewingAt: string,
    readonly phone: string,
    readonly locale: string,
    readonly guarantor: string,
    readonly employment: string,
    readonly gender: string,
    readonly income: number,
    readonly household: string,
    readonly householdType: string,
    readonly pets: boolean,
    readonly term: number,
    readonly availableSince: string,
    readonly extraInfoFormLastSentOn: string | undefined,
    readonly birthday: string,
    readonly messages: ReadonlyArray<string>,
    readonly notes: ReadonlyArray<string>,
    readonly status: string,
    readonly listing: string,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly fullName: string,
    readonly hasAcceptedAgentPrivacyPolicy: boolean,
    readonly screeningRequestId: string,
    readonly screeningRequestStatus: string,
    readonly screeningRequestRating: number,
    readonly screeningRequestOutcome: string,
    readonly isListingTenant: boolean,
    readonly hasCoTenants: boolean,
    readonly hasCoTenantsWithInvitedScreeningRequests: boolean,
}

export default class CandidateJsonToModelConverter {

    public static convert(obj: CandidateJSON): CandidateModel {
        return new CandidateModel(
            obj.id,
            obj.firstName,
            obj.lastName,
            obj.email,
            stringToEnum(obj.viewingInvitationStatus, CandidateViewingStatus),
            obj.invitedForViewingAt,
            obj.phone,
            obj.locale,
            obj.guarantor,
            obj.employment,
            obj.gender,
            obj.income,
            obj.household,
            obj.householdType,
            obj.pets,
            obj.term,
            obj.availableSince,
            obj.extraInfoFormLastSentOn,
            obj.birthday,
            obj.messages,
            obj.notes,
            stringToEnum(obj.status, CandidateStatus),
            obj.listing,
            obj.createdAt,
            obj.updatedAt,
            obj.fullName,
            obj.hasAcceptedAgentPrivacyPolicy,
            obj.screeningRequestId,
            obj.screeningRequestStatus ? stringToEnum(obj.screeningRequestStatus, CandidateScreeningRequestStatusEnum) : null,
            obj.screeningRequestRating,
            obj.screeningRequestOutcome ? stringToEnum(obj.screeningRequestOutcome, CandidateScreeningRequestOutcomeEnum) : null,
            obj.isListingTenant,
            obj.hasCoTenants,
            obj.hasCoTenantsWithInvitedScreeningRequests
        )
    }
}

