import {request} from '../../../utils/api';
import {LISTING_STATES} from '../../../utils/constants';
import {getAllListings} from './getAllListings';

export const setListingStatus = (token, ids, status) => async (dispatch) => {
    dispatch({type: 'START_LOADING'});

    // eslint-disable-next-line no-undef
    if (window.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED === 'true') {
        request(token)
            .post(`/api/bulk/transactional_listings/status/${status}`, {listingIds: ids})
            .then(() => {
                dispatch(
                    getAllListings(token, {
                        itemsPerPage: 25,
                        page: 1,
                        status: status === LISTING_STATES.AVAILABLE ? LISTING_STATES.ARCHIVED : LISTING_STATES.AVAILABLE,
                        pagination: true,
                    })
                );
            })
            .catch((e) => {
                dispatch({type: 'STOP_LOADING'});
                console.error(e);
            })
            .finally(() => dispatch({type: 'STOP_LOADING'}));
    } else {
        request(token)
            .post(`/api/bulk/listings/status/${status}`, {listingIds: ids})
            .then(() => {
                dispatch(
                    getAllListings(token, {
                        itemsPerPage: 25,
                        page: 1,
                        status: status === LISTING_STATES.AVAILABLE ? LISTING_STATES.ARCHIVED : LISTING_STATES.AVAILABLE,
                        pagination: true,
                    })
                );
            })
            .catch((e) => {
                dispatch({type: 'STOP_LOADING'});
                console.error(e);
            })
            .finally(() => dispatch({type: 'STOP_LOADING'}));
    }
};
