import api, {request, wait} from '../../../utils/api';
import {UI_ACTIONS, WAIT_TIME} from '../../../utils/constants';

export const getListingDetails = (token, id) => dispatch => {
    dispatch({type: UI_ACTIONS.START_LOADING});

    // eslint-disable-next-line no-undef
    if (window.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED === 'true') {
        api.get(`api/transactional_listings/${id}`)
            .then((response) => {
                dispatch({
                    type: 'GET_LISTING_DETAILS',
                    payload: response,
                });
            })
            .catch((e) => {
                dispatch({type: UI_ACTIONS.STOP_LOADING});
                console.error(e);
            });
    } else {
        request(token).get(`/api/lead_listings/${id}`)
            .then(response => {
                dispatch({
                    type: 'GET_LISTING_DETAILS',
                    payload: response.data
                });
            })
            .then(() => wait(WAIT_TIME))
            .catch((e) => {
                dispatch({type: UI_ACTIONS.STOP_LOADING});
                console.error(e);
            });
    }
};
