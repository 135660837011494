import i18n from 'i18next'
import gbFlag from '../../assets/img/flags/gb-flag.png'
import nlFlag from '../../assets/img/flags/nl-flag.png'

export interface LanguageSwitchButtonsProps {
    readonly className?: string
}

export default function LanguageSwitchButtons(props: LanguageSwitchButtonsProps) {
    return (
        <div className={`${props.className ? props.className : ''} flex shadow-sm`}>
            <button
                onClick={() => i18n.changeLanguage('en')}
                type="button"
                className="px-4 py-2 rounded-l-md border border-r-transparent border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary focus:border-r"
            >
                <div className='flex'>
                    <img src={gbFlag} alt="English" className="w-5 h-5 mr-2" />
                    <p>English</p>
                </div>
            </button>
            <button
                onClick={() => i18n.changeLanguage('nl')}
                type="button"
                className="px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
            >
                <div className='flex'>
                    <img src={nlFlag} alt="Nederlands" className="w-5 h-5 mr-2" />
                    <p>Nederlands</p>
                </div>
            </button>
        </div>
    )
}
