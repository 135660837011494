/* eslint-disable no-undef */
import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {BrowserRouter} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react'
import TestAuth0Provider from './testAuth0Provider/TestAuth0Provider';

if (process.env.NODE_ENV === 'development') {
    (window as any).REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    (window as any).REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
    (window as any).REACT_APP_AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
    (window as any).REACT_APP_AUTH_SCOPE = process.env.REACT_APP_AUTH_SCOPE;
    (window as any).REACT_APP_IS_AUTH_IN_TEST_MODE = process.env.REACT_APP_IS_AUTH_IN_TEST_MODE;

    (window as any).REACT_APP_AUTH_SSO_DOMAIN = process.env.REACT_APP_AUTH_SSO_DOMAIN;
    (window as any).REACT_APP_AUTH_SSO_CLIENT_ID = process.env.REACT_APP_AUTH_SSO_CLIENT_ID;
    (window as any).REACT_APP_AUTH_SSO_AUDIENCE = process.env.REACT_APP_AUTH_SSO_AUDIENCE;
    (window as any).REACT_APP_AUTH_SSO_SCOPE = process.env.REACT_APP_AUTH_SSO_SCOPE;
    (window as any).REACT_APP_IS_AUTH_IN_TEST_MODE = process.env.REACT_APP_IS_AUTH_IN_TEST_MODE;

    (window as any).REACT_APP_LEADFLOW_CANDIDATES_API = process.env.REACT_APP_LEADFLOW_CANDIDATES_API;
    (window as any).REACT_APP_LEADFLOW_LEADS_API = process.env.REACT_APP_LEADFLOW_LEADS_API;
    (window as any).REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED = process.env.REACT_APP_IS_TRANSACTIONAL_LISTING_ENABLED;
}

const AuthWrapperWithHistory = ({ children }) => {

    const [isLoginSSO, setIsLoginSSO] = useState(localStorage.getItem('isLoginSSO') === 'true')

    useEffect(() => {
        sessionStorage.removeItem('redirectToAfterLogin')
        sessionStorage.setItem('redirectToAfterLogin', `${window.location.pathname}${location.search}`)

        window.addEventListener('storage', () => {
            setIsLoginSSO(localStorage.getItem('isLoginSSO') === 'true')
            if ((localStorage.getItem('isLoginSSO') === 'true') !== isLoginSSO) {
                window.location.reload()
            }
        })
    }, [])

    return (
        <BrowserRouter>
            {(window as any).REACT_APP_IS_AUTH_IN_TEST_MODE === 'true' ?
                <TestAuth0Provider>
                    {children}
                </TestAuth0Provider> :
                <Auth0Provider
                    domain={isLoginSSO ? (window as any).REACT_APP_AUTH_SSO_DOMAIN : (window as any).REACT_APP_AUTH_DOMAIN}
                    clientId={isLoginSSO ? (window as any).REACT_APP_AUTH_SSO_CLIENT_ID : (window as any).REACT_APP_AUTH_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience={isLoginSSO ? (window as any).REACT_APP_AUTH_SSO_AUDIENCE : (window as any).REACT_APP_AUTH_AUDIENCE}
                    scope={isLoginSSO ? (window as any).REACT_APP_AUTH_SSO_SCOPE : (window as any).REACT_APP_AUTH_SCOPE}
                    useRefreshTokens
                    cacheLocation={'localstorage'}
                >
                    {children}
                </Auth0Provider>
            }

        </BrowserRouter>
    );
};

AuthWrapperWithHistory.propTypes = {
    children: PropTypes.any.isRequired,
};

export default AuthWrapperWithHistory;
