import React, { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import logo from '../../assets/img/logo.png';
import BreadCrumbNavigation from './BreadCrumbNavigation';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const navigation = [
    { name: 'Advertenties', href: '/dashboard/advertenties', current: false }
];

function TopNavigation() {
    const { logout, user } = useAuth();
    const { t } = useTranslation();

    const userNavigation = [
        {
            name: t('navigation.signout'),
            href: '#',
            action: () => logout({ returnTo: window.location.origin }),
        },
    ];

    return (
        <>
            <Disclosure as="nav" className="bg-white">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="relative h-24 flex items-center justify-between border-b border-gray-200">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 flex flex-row items-center justify-center">
                                        <img className="h-14 w-auto" src={logo} alt="Logo" />
                                        <span className="bg-red-400 text-white rounded-md px-3 py-0.5 font-semibold
                                         h-auto mx-2 text-sm">
                                            beta
                                        </span>
                                    </div>
                                    <div className="ml-10 sm:block hidden">
                                        <BreadCrumbNavigation />
                                    </div>
                                </div>
                                { user ? (
                                    <>
                                        <div className="flex lg:hidden">
                                            <Disclosure.Button className="bg-gray-50 p-2 inline-flex items-center
                                            justify-center rounded-md text-gray-400 hover:text-gray-500
                                            hover:bg-gray-100
                                            focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                            focus:border-[1px]">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>

                                        <div className="hidden lg:block lg:ml-4">
                                            <div className="flex items-center">
                                                <a href='https://help.leadflow.rent/portal/nl/signin' target='_blank' rel='noreferrer'>
                                                    <button type='button' className="inline-flex items-center rounded-md
                                                    border border-gray-300 bg-white px-4 py-2 text-sm font-medium
                                                    text-gray-700 shadow-sm hover:bg-gray-50
                                                    focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300">
                                                        Helpdesk
                                                    </button>
                                                </a>
                                                <Menu as="div" className="ml-3 relative flex-shrink-0">
                                                    <Menu.Button className="bg-gray-50 rounded-full flex text-base text-white
                                                    focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                                    focus:border-[1px]">
                                                        <span className="sr-only">Open user menu</span>
                                                        <img
                                                            className="rounded-full h-10 w-10"
                                                            src={user.picture || `https://via.placeholder.com/420/cecece/FFFFFF?text=${user.email
                                                                .toUpperCase()
                                                                .slice(0, 1)}`}
                                                            alt=""
                                                        />
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95">
                                                        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48
                                                        rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
                                                        focus:outline-none">
                                                            <Menu.Item>
                                                                <a
                                                                    className="py-2 px-4 text-base flex flex-row
                                                                    font-medium text-gray-900 bg-gray-50"
                                                                >
                                                                    Ingelogd als: {user.email}
                                                                </a>
                                                            </Menu.Item>
                                                            < hr />
                                                            {userNavigation.map((item) => (
                                                                <Menu.Item key={item.name} onClick={item.action}>
                                                                    {({ active }) => (
                                                                        <a
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100' : '',
                                                                                'block py-2 px-4 text-base text-gray-700'
                                                                            )}>
                                                                            {item.name}
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </>
                                ): null}
                            </div>
                        </div>

                        <Disclosure.Panel className="bg-gray-50 border-b border-gray-200 lg:hidden">
                            <div className="pt-4 pb-3 border-t border-gray-200">
                                { user ? (
                                    <div className="px-5 flex items-center">
                                        <div className="flex-shrink-0">
                                            <img className="rounded-full h-10 w-10" src={user.imageUrl} alt="" />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-500">{user.email}</div>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="mt-3 px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className="block rounded-md py-2 px-3 text-base font-medium
                                            text-gray-900 hover:bg-gray-100">
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                    {userNavigation.map((item) => (
                                        <Disclosure.Button
                                            onClick={() => item.action()}
                                            key={item.name}
                                            as="a"
                                            className="block rounded-md py-2 px-3 text-base font-medium
                                            text-gray-900 hover:bg-gray-100">
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    );
}

TopNavigation.propTypes = {};

export default TopNavigation;
