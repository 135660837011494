/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import './main.css';
import 'remixicon/fonts/remixicon.css';
import './utils/i18n'; // Needed for init
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthWrapperWithHistory from './utils/AuthWrapperWithHistory';
import WrapperBase from './utils/WrapperBase';
import { Provider } from 'react-redux';
import store from './redux/store';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.NODE_ENV === 'development') {
    (window as any).REACT_APP_HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID;
    (window as any).REACT_APP_HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;
    (window as any).REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    (window as any).NODE_ENV = process.env.NODE_ENV;
}

Sentry.init({
    dsn: (window as any).REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: (window as any).NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

Hotjar.init(+(window as any).REACT_APP_HOTJAR_SITE_ID, +(window as any).REACT_APP_HOTJAR_VERSION);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthWrapperWithHistory>
                <WrapperBase>
                    <App />
                </WrapperBase>
            </AuthWrapperWithHistory>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
