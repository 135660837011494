import {useRef} from 'react'
import ListingsTableService from '../../services/listingsTableService'
import ListingsTable from '../../components/tables/listingsTable/ListingsTable'

export default function TableSandboxPage() {
    
    const listingTableService = useRef(new ListingsTableService())

    return (
        <div style={{ margin: 50 }}>
            <h1>Listing Table-------------------------</h1>
            <ListingsTable service={listingTableService.current} />
        </div>
    )
}
