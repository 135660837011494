import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Modal from '../../modal/Modal'
import style from './UnarchiveListingModal.module.css'
import UnarchiveListingModalModel from './logic/models/unarchiveListingModalModel';
import UnarchiveModalActionType from './logic/models/unarchiveListingModalActionType';
import {InfoIcon} from '../../generics/infoIcon/InfoIcon';
import TranslationModel from '../../../models/translationModel';
import EnrichmentModeEnum from '../../../enums/enrichmentModeEnum';
import Checkbox from '../../forms/checkbox/Checkbox'

export interface UnarchiveModalProps {
    readonly model: UnarchiveListingModalModel
    readonly onButtonClick: (listingId: string, enrichmentMode: EnrichmentModeEnum, actionType: UnarchiveModalActionType) => void
}

export default function UnarchiveListingModal(props: UnarchiveModalProps) {

    const { t } = useTranslation()

    const [model, setModel] = useState(props.model)

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    function handleAutoEnrichmentCheckboxChange(isChecked: boolean) {
        setModel(prev => prev.withIsAutoEnrichmentEnabled(isChecked))
    }

    function handleModalButtonClick(acionType: UnarchiveModalActionType) {
        props.onButtonClick(
            model.listingId,
            model.isAutoEnrichmentEnabled ? EnrichmentModeEnum.Auto : EnrichmentModeEnum.Manual,
            acionType
        )
    }

    return (
        <Modal
            model={model}
            onButtonClick={(acionType: UnarchiveModalActionType) => handleModalButtonClick(acionType)}
        >
            <p className={style.text}>{t('listings.unarchive.modal.info')}</p>
            <div className={style.checkboxContainer} data-testid="autoEnrichment-checkbox">
                <Checkbox
                    id="autoEnrichment"
                    name="autoEnrichment"
                    isChecked={model.isAutoEnrichmentEnabled}
                    onChange={(e) =>  handleAutoEnrichmentCheckboxChange(e.target.checked)}
                />
                <label htmlFor="autoEnrichment" className={style.text}>
                    {t('listings.unarchive.modal.checkbox')}
                </label>
                <InfoIcon tooltipText={new TranslationModel('listings.unarchive.modal.checkbox.tooltip')} />
            </div>
        </Modal>
    )
}
