import ListingTableActionType from '../components/tables/listingsTable/logic/models/listingTableActionType'
import TableModel from '../components/tables/table/logic/models/tableModel'
import TableServiceInterface from '../components/tables/table/logic/service/tableServiceInterface'
import ListingsTableJsonToTableModelConverter from '../converters/listingsTableJsonToModelConverter'
import api from '../utils/api'
import PaginationBarModel from '../components/tables/pagination/logic/model/paginationBarModel';

export default class ListingsTableService implements TableServiceInterface<ListingTableActionType> {

    public async getTable(currentPage: number, itemsPerPage: number): Promise<TableModel<ListingTableActionType>> {
        const res = await api.getJsonLd('/api/lead_listings', {
            page: currentPage,
            itemsPerPage: itemsPerPage,
            pagination: true,
            status: 'available'
        })
        const model = ListingsTableJsonToTableModelConverter.convert(res['hydra:member'])
        const paginationBarModel = new PaginationBarModel({ totalItems: res['hydra:totalItems'], currentPage, itemsPerPage })

        return model.withPaginationBarModel(paginationBarModel)
    }
}
