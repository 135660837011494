import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { FilterIcon } from '@heroicons/react/outline'
import { HOUSEHOLD_TYPE } from '../../utils/constants'
import Button from '../buttons/button/Button'
import { useTranslation } from 'react-i18next'
import ButtonType from '../buttons/button/logic/models/buttonType'
import Checkbox from '../forms/checkbox/Checkbox'
import RadioButton from '../forms/radiobutton/RadioButton'
import styles from './FiltersDropdown.module.css'

function FiltersDropdown(props) {
    const { pets, income, householdType, setPets, setIncome, setHouseholdType,
        isFiltersDropdownOpen, resetFilter, toggleFiltersDropdown, selectHousehold } = props
    const { t } = useTranslation()

    return (
        <span className="relative inline-flex rounded-md sm:col-span-2 pr-[282px] mr-3">
            <button
                onClick={() => toggleFiltersDropdown(!isFiltersDropdownOpen)}
                type="button"
                className="relative inline-flex items-center px-4 py-2.5 rounded-md border
        border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50
        focus:z-10 focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300"
            >
                <FilterIcon className="h-4 w-4 text-gray-700 mr-2" aria-hidden="true" />
                Filters
            </button>
            <Menu as="span" className="-ml-px relative block z-30">

                <Transition
                    show={!!isFiltersDropdownOpen}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-left absolute z-0 left-4 -mr-1 w-54 rounded-md
          shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                            className="z-30 rounded-lg shadow p-4 absolute top-0 bg-white"
                            style={{ minWidth: '700px' }}
                        >
                            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div className="sm:col-span-2 col-span-1">
                                    <label
                                        htmlFor="city"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Minimale inkomen
                                    </label>
                                    <div className="mt-4">
                                        <input
                                            value={income}
                                            onChange={(e) => setIncome(e.target.value)}
                                            type="number"
                                            name="income"
                                            id="income"
                                            autoComplete="income-level2"
                                            className="shadow-sm focus:ring-secondary focus:border-secondary
                      block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2 col-span-2">
                                    <div className="space-y-4">
                                        <label
                                            htmlFor="pets"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Huisdieren
                                        </label>
                                        <div className="flex items-center">
                                            <div className={styles.radioButton}>
                                                <RadioButton
                                                    onChange={() => setPets(1)}
                                                    isChecked={pets === 1}
                                                    id="pets-yes"
                                                    name="pets"
                                                />
                                            </div>
                                            <label
                                                htmlFor="push-yes"
                                                className="ml-3 block text-sm font-medium text-gray-700"
                                            >
                                                Ja
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <div className={styles.radioButton}>
                                                <RadioButton
                                                    onChange={() => setPets(0)}
                                                    isChecked={pets === 0}
                                                    id="pets-no"
                                                    name="pets"
                                                />
                                            </div>
                                            <label
                                                htmlFor="push-no"
                                                className="ml-3 block text-sm font-medium text-gray-700"
                                            >
                                                Nee
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <div className={styles.radioButton}>
                                                <RadioButton
                                                    isChecked={pets === null}
                                                    onChange={() => setPets(null)}
                                                    id="pets-all"
                                                    name="pets"
                                                />
                                            </div>
                                            <label
                                                htmlFor="pets-all"
                                                className="ml-3 block text-sm font-medium text-gray-700"
                                            >
                                                Geen voorkeur
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-2 col-span-2">
                                    <label
                                        htmlFor="household"
                                        className="mb-4 block text-sm font-medium text-gray-700"
                                    >
                                        Huishouden
                                    </label>
                                    <div className="relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <div className={styles.checkboxContainer}>
                                                <Checkbox
                                                    isChecked={householdType.includes(HOUSEHOLD_TYPE.SINGLE)}
                                                    onChange={() => selectHousehold(HOUSEHOLD_TYPE.SINGLE)}
                                                    id="single-household"
                                                    name="single-household"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label
                                                htmlFor="single-household"
                                                className="font-medium text-gray-700"
                                            >
                                                Alleenstaand
                                            </label>
                                        </div>
                                    </div>
                                    <div className="my-4 relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <Checkbox
                                                isChecked={householdType.includes(HOUSEHOLD_TYPE.WITH_FAMILY)}
                                                onChange={() => selectHousehold(HOUSEHOLD_TYPE.WITH_FAMILY)}
                                                id="family-household"
                                                name="family-household"
                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label
                                                htmlFor="family-household"
                                                className="font-medium text-gray-700"
                                            >
                                                Gezin
                                            </label>
                                        </div>
                                    </div>
                                    <div className="my-4 relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <div className={styles.checkboxContainer}>
                                                <Checkbox
                                                    isChecked={householdType.includes(HOUSEHOLD_TYPE.WITH_PARTNER)}
                                                    onChange={() => selectHousehold(HOUSEHOLD_TYPE.WITH_PARTNER)}
                                                    id="partner-household"
                                                    name="partner-household"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label
                                                htmlFor="partner-household"
                                                className="font-medium text-gray-700"
                                            >
                                                Samenwonend
                                            </label>
                                        </div>
                                    </div>
                                    <div className="my-4 relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <div className={styles.checkboxContainer}>
                                                <Checkbox
                                                    isChecked={householdType.includes(HOUSEHOLD_TYPE.WITH_FRIENDS)}
                                                    onChange={() => selectHousehold(HOUSEHOLD_TYPE.WITH_FRIENDS)}
                                                    id="shared-household"
                                                    name="shared-household"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label
                                                htmlFor="shared-household"
                                                className="font-medium text-gray-700"
                                            >
                                                Delers
                                            </label>
                                        </div>
                                    </div>
                                    <div className="my-4 relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <div className={styles.checkboxContainer}>
                                                <Checkbox
                                                    isChecked={householdType.length < 1}
                                                    onChange={() => setHouseholdType([])}
                                                    id="no-household"
                                                    name="no-household"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label
                                                htmlFor="no-household"
                                                className="font-medium text-gray-700"
                                            >
                                                Geen voorkeur
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end space-x-2">
                                    <Button
                                        title={t('candidates.filter.close')}
                                        buttonType={ButtonType.Secondary}
                                        onClick={() => toggleFiltersDropdown(false)}
                                    />
                                    <Button
                                        title={t('candidates.filter.reset')}
                                        buttonType={ButtonType.Primary}
                                        onClick={() => resetFilter()}
                                    />

                                </div>
                            </div>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </span>
    )
}

FiltersDropdown.propTypes = {
    income: PropTypes.any,
    setIncome: PropTypes.func.isRequired,
    pets: PropTypes.any,
    setPets: PropTypes.func.isRequired,
    householdType: PropTypes.array.isRequired,
    setHouseholdType: PropTypes.func.isRequired,
    isFiltersDropdownOpen: PropTypes.bool.isRequired,
    toggleFiltersDropdown: PropTypes.func.isRequired,
    selectHousehold: PropTypes.func.isRequired,
    resetFilter: PropTypes.func
}

export default FiltersDropdown
