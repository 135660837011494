import styles from './CheckboxColumnHeader.module.css'

export interface CheckboxColumnHeaderProps {
    readonly rowSpan: number
}

export default function CheckboxColumnHeader(props: CheckboxColumnHeaderProps) {
    return (
        <th
            rowSpan={props.rowSpan}
            className={styles.root}
        >
            <input type="checkbox" />
        </th>

    )
}
