import Table from '../table/Table'
import ListingsTableService from '../../../services/listingsTableService'
import ListingTableActionType from './logic/models/listingTableActionType'

export interface ListingsTableProps {
    readonly service: ListingsTableService
}

export default function ListingsTable(props: ListingsTableProps) {

    function handleEvent(ev: ListingTableActionType, id: string) {
        console.log('here the service will take care of things depending on event type: ', ev, id)
    }

    return <Table
        service={props.service}
        onEvent={(ev, id) => handleEvent(ev, id)}
    />
}
