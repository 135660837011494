import {Routes, Route, Navigate, useNavigate, useSearchParams} from 'react-router-dom'
import LoginPage from './pages/login/LoginPage'
import ListingsOverview from './pages/dashboard/listings/ListingsOverview'
import ListingDetails from './pages/dashboard/listings/ListingDetails'
import EnrichingFormPage from './pages/enriching-form/EnrichingFormPage'
import CandidateProfileContainer from './pages/dashboard/candidates/CandidateProfileContainer'
import BasicNotification from './components/notifications/BasicNotification'
import { useSelector } from 'react-redux'
import AgentsOverview from './pages/admin/AgentsOverview'
import AgentDetails from './pages/admin/agents/AgentDetails'
import SubmitViewingInvitationPage from './pages/viewing/SubmitViewingInvitationPage'
import TableSandboxPage from './pages/tableSandboxPage/TableSandboxPage'
import CookieDeclarationPage from './pages/cookies/CookieDeclarationPage'
import MarketingMessagesSubscribePage from './pages/marketingMessagesSubscribe/MarketingMessagesSubscribePage'
import MarketingMessagesUnsubscribePage from './pages/marketingMessagesUnsubscribe/MarketingMessagesUnsubscribePage'
import EmailAddressConfirmationPage from './pages/emailAddressConfirmation/EmailAddressConfirmationPage'
import {useEffect} from 'react'
import { useFeature, GrowthBookProvider} from '@growthbook/growthbook-react'
import { growthbook } from './utils/analytics'
import useAuth from './hooks/useAuth';
import SSOLoginPage from './pages/login/SSOLoginPage'
import LogoutPage from './pages/LogoutPage'
import MergeDuplicateTransactionalListingsPage
    from './pages/admin/mergeDuplicateTransactionalListings/MergeDuplicateTransactionalListingsPage'

export default function App() {
    const navigate = useNavigate()
    const { isAuthenticated, isLoading } = useAuth()
    const { notifications } = useSelector((state: any) => state.ui)
    const REDIRECT_ON_LOGIN = useFeature('redirect_on_login').value

    useEffect(() => {
        growthbook.init({ streaming: true });

        window.addEventListener('CookiebotOnAccept', function (e) {
            document.dispatchEvent(new CustomEvent('growthbookpersist'));
        }, false);
    }, []);

    const persistGrowthbook = () => {
        if ((window as any).Cookiebot.consented) {
            document.dispatchEvent(new CustomEvent('growthbookpersist'));
        }
    }

    useEffect(() => {
        if (growthbook.ready) {
            if (!(window as any).Cookiebot || (window as any).Cookiebot.loaded === false) {
                window.addEventListener('CookiebotOnLoad', function () {
                    persistGrowthbook()
                }, false);
            } else {
                persistGrowthbook()
            }
        }
    }, [growthbook.ready]);

    useEffect(() => {
        if (REDIRECT_ON_LOGIN) {
            const redirectToAfterLogin = sessionStorage.getItem('redirectToAfterLogin')
            if (isAuthenticated && redirectToAfterLogin) {
                navigate(redirectToAfterLogin, { replace: true })
            }
        }
    }, [isAuthenticated]);

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
            }}>
            {isLoading ? (
                <>
                    <div>
                        <p>Please check for another tab or window to log in.</p>
                    </div>
                </>
            ) : (
                <>
                    <GrowthBookProvider growthbook={growthbook}>
                        <Routes>
                            <Route path="/cookies" element={<CookieDeclarationPage />} />
                            <Route path="/logout" element={<LogoutPage />} />
                            {isAuthenticated ? (
                                <>
                                    <Route path="tableSandbox" element={<TableSandboxPage />} />
                                    <Route path="/admin" element={<AgentsOverview />} />
                                    <Route path="/admin/agents/:id" element={<AgentDetails />} />
                                    <Route path="/admin/dubbele-advertenties" element={<MergeDuplicateTransactionalListingsPage />} />
                                    <Route path="/applicant-form/:id" element={<EnrichingFormPage />} />
                                    <Route path="/dashboard/listings" element={<ListingsOverview />} />
                                    <Route path="/dashboard/listings/:id" element={<ListingDetails />} />
                                    <Route path="/dashboard/advertenties" element={<ListingsOverview />} />
                                    <Route path="/dashboard/advertenties/:id" element={<ListingDetails/>}/>
                                    <Route path="/dashboard/advertenties/:id/:status" element={<ListingDetails />} />
                                    <Route
                                        path="/dashboard/advertenties/:id/kandidaten/:pid"
                                        element={<CandidateProfileContainer />}
                                    />
                                    <Route
                                        path="/"
                                        element={<Navigate to="/dashboard/advertenties" replace={true} />}
                                    />
                                    <Route path="*" element={<Navigate to="/dashboard/advertenties" />} />
                                </>
                            ) : (
                                <>
                                    <Route path="login" element={<LoginPage />} />
                                    <Route path="sso-login" element={<SSOLoginPage />} />
                                    <Route path="/applicant-form/:id" element={<EnrichingFormPage />} />
                                    <Route path="/viewing/:id" element={<SubmitViewingInvitationPage />} />
                                    <Route path="/subscribe-to-marketing-messages/:id" element={<MarketingMessagesSubscribePage />} />
                                    <Route path="/subscribe-to-marketing-messages/:id/:listingCity" element={<MarketingMessagesSubscribePage />} />
                                    <Route path="/unsubscribe-from-marketing-messages/:id" element={<MarketingMessagesUnsubscribePage />} />
                                    <Route path="/email-address-confirmation/:id" element={<EmailAddressConfirmationPage />} />
                                    <Route path="/email-address-confirmation/:id/:listingCity" element={<EmailAddressConfirmationPage />} />
                                    <Route path="*" element={<Navigate to={localStorage.getItem('isLoginSSO') === 'true' ? '/sso-login' : '/login'} />} />
                                </>
                            )}
                        </Routes>
                    </GrowthBookProvider>
                </>
            )}
            <div
                aria-live="assertive"
                className="fixed inset-0 flex flex-col
        px-4 py-6 pointer-events-none z-50 justify-end items-end">
                {notifications.length > 0 && (
                    <>
                        {notifications.map((notif, index) => (
                            <BasicNotification
                                key={index}
                                message={notif.message}
                                id={notif.id}
                                type={notif.type}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
