import EnumType from '../../../../../enums/general/enumType'
import CellRendererInterface from '../../cellRenderers/cellRendererInterface';

export default abstract class CellModel<M, E extends EnumType> {
    constructor(
        public readonly rowId: string,
        public readonly model: M,
        public readonly renderer: CellRendererInterface<M>
    ) {}
    public abstract getActionType(): E
}
