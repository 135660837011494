import React, {useState, useEffect, useRef} from 'react';
import TopNavigation from '../../../components/navigation/TopNavigation';
import OverviewHeader from '../../../components/headers/OverviewHeader';
import {useDispatch} from 'react-redux';

import {getAllListings} from '../../../redux/actions/listings/getAllListings';
import {useSelector} from 'react-redux';
import Deprecated_ListingsTable from '../../../components/tables/Deprecated_ListingsTable';
import PaginationBar from '../../../components/tables/pagination/PaginationBar';
import {setListingStatus} from '../../../redux/actions/listings/setListingStatus';
import {LISTING_STATES} from '../../../utils/constants';
import PaginationBarModel from '../../../components/tables/pagination/logic/model/paginationBarModel';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function ListingsOverview() {
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users);
    const {listings, totalItems} = useSelector((state) => state.listings.data);
    const {isLoading} = useSelector((state) => state.ui);
    const [query, setQuery] = useState('');
    const [selectedTab, selectTab] = useState('available');

    const [paginationBarModel, setPaginationBarModel] = useState(new PaginationBarModel({ totalItems: 0, currentPage: 1, itemsPerPage: 25 }));
    const [selectedListings, setSelectedListings] = useState([]);
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    const tabs = [
        {
            id: 1,
            name: 'Open',
            value: 'available',
            count: listings.map((listing) => listing.status === LISTING_STATES.AVAILABLE).length,
            current: true,
            icon: <i className="ri-inbox-line text-xl -mt-0.5 mr-2"></i>
        },
        {
            id: 2,
            name: 'Archief',
            value: 'archived',
            count: listings.map((listing) => listing.status === LISTING_STATES.ARCHIVED).length,
            current: false,
            icon: <i className="ri-archive-line -mt-0.5 text-xl mr-2"/>
        },
    ];

    const fetchListingsOnLoad = () => {
        dispatch(getAllListings(token, {
            page: paginationBarModel.value.currentPage,
            itemsPerPage: paginationBarModel.value.itemsPerPage,
            pagination: true,
            listing: query.length >= 2 ? query : null,
            status: selectedTab
        }));
    };

    const changeBulkListingStatus = (ids, status) => {
        setSelectedListings([])
        dispatch(setListingStatus(token, ids, status))
    };

    const changeSingleListingStatus = (id, status) => {
        changeBulkListingStatus([id], status);
    };

    useEffect(() => {
        setPaginationBarModel(prev => prev.with({ totalItems: totalItems }))
    }, [totalItems]);

    useEffect(() => {
        if (token) {
            fetchListingsOnLoad();
        }
    }, [dispatch, token, selectedTab, paginationBarModel, query]);

    return (
        <>
            <TopNavigation/>
            <OverviewHeader type="listings"/>
            <div className="px-4 sm:px-6 lg:px-8">
                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200 flex flex-row justify-between w-full">
                                    <nav className="mt-2 -mb-px flex space-x-2" aria-label="Tabs">
                                        {tabs.map(tab => (
                                            <a
                                                key={tab.name}
                                                href={tab.href}
                                                className={classNames('text-md',
                                                    tab.value === selectedTab
                                                        ? 'border-primary text-primary font-semibold flex flex-row justify-center'
                                                        : 'border-transparent text-gray-400 hover:text-gray-600 hover:border-gray-200',
                                                    'whitespace-nowrap py-4 px-4 border-b-2 font-medium flex flex-row justify-center cursor-pointer'
                                                )}
                                                onClick={() => selectTab(tab.value)}
                                            >
                                                {tab.icon}
                                                {tab.name}
                                            </a>
                                        ))}

                                    </nav>
                                    <div
                                        className="max-w-lg w-full lg:max-w-xs ml-auto flex-col flex justify-center px-3">
                                        <label htmlFor="search" className="sr-only">
                                            Zoeken
                                        </label>
                                        <div className="relative text-gray-400 focus-within:text-gray-500">
                                            <div
                                                className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                                <i className="ri-search-line text-xl"></i>
                                            </div>
                                            <input
                                                onChange={(e) => {
                                                    setQuery(e.target.value)
                                                    setPaginationBarModel(prev => prev.with({ currentPage: 1 }))
                                                }}
                                                id="search"
                                                className="block w-full bg-white py-1.5 pl-10 pr-3 border border-gray-300
                        rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none
                        focus:ring-[4px] focus:ring-primary-100 focus:border-primary-300
                        focus:placeholder-gray-500 sm:text-base"
                                                placeholder="Zoeken"
                                                type="search"
                                                name="search"
                                                value={query}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Deprecated_ListingsTable
                            data={listings}
                            selectedListings={selectedListings}
                            setSelectedListings={setSelectedListings}
                            totalItems={totalItems}
                            isLoading={isLoading}
                            itemsPerPage={paginationBarModel.value.itemsPerPage}
                            changeBulkListingStatus={changeBulkListingStatus}
                            changeSingleListingStatus={changeSingleListingStatus}
                            setIndeterminate={setIndeterminate}
                            indeterminate={indeterminate}
                            listingStatus={selectedTab}
                            checked={checked}
                            setChecked={setChecked}
                        />
                        <PaginationBar
                            model={paginationBarModel}
                            onPageChange={(model) => setPaginationBarModel(model)}
                            onItemsPerPageChange={(model) => setPaginationBarModel(model)}
                        />
                    </div>
                </main>
            </div>
        </>
    );
}

ListingsOverview.propTypes = {};

export default ListingsOverview;
