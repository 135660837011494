import EnumType from '../../../../../enums/general/enumType';
import ColumnModel from './columnModel';
import CellModel from './cellModel';
import RowModel from './rowModel';
import TableModel from './tableModel';

export default class ColumnGroupModel<E extends EnumType> {

    private constructor(
        public readonly title: string,
        public readonly widthRatio: number,
        public readonly columns: ReadonlyArray<ColumnModel<any, E>> = []
    ) { }

    public static createWithTitle<E extends EnumType>(title: string, columns: ColumnModel<any, E>[], widthRatio = TableModel.MINIMUM_COLUMN_WIDTH_RATIO): ColumnGroupModel<E> {
        return new ColumnGroupModel(title, widthRatio, columns)
    }

    public static createWithoutTitle<E extends EnumType>(columns: ColumnModel<any, E>[], widthRatio = TableModel.MINIMUM_COLUMN_WIDTH_RATIO): ColumnGroupModel<E> {
        return new ColumnGroupModel('', widthRatio, columns)
    }

    public getAmountOfColumns(): number {
        return this.columns.length
    }

    public getRelativeColumnWidthRatio(col: ColumnModel<any, E>): number {
        return col.widthRatio * this.widthRatio
    }

    public getRows(): RowModel[] {
        if (this.columns.length === 0) {
            return []
        }
        const firstColumn = this.columns[0]
        return firstColumn.cells.map((_, i) => new RowModel(this.collectCellsOfRow(i)))
    }

    private collectCellsOfRow(rowIndex: number): CellModel<any, E>[] {
        return this.columns.reduce(
            (prev, curr) => [...prev, curr.cells[rowIndex]],
            [] as CellModel<any, E>[]
        )
    }
}
