import ArchiveToggleButtonModel from './logic/models/archiveToggleButtonModel';
import ArchiveToggleButtonStatus from './logic/models/archiveToggleButtonStatus';

export interface ArchiveToggleButtonProps {
    readonly model: ArchiveToggleButtonModel
    readonly onClick?: () => void
}

export default function ArchiveToggleButton(props: ArchiveToggleButtonProps) {
    return (
        <button
            type="button"
            onClick={() => props.onClick?.()}
            className="
                inline-flex items-center px-3 py-2 border 
                border-gray-300 shadow-sm 
                font-medium rounded text-gray-700 bg-white 
                hover:bg-gray-50 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-secondary
            "
        >
            <i className={props.model.status === ArchiveToggleButtonStatus.Archive ?  'ri-archive-line' : 'ri-inbox-line'}></i>
        </button>
    )
}
