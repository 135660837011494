import React from 'react';
import {useFormik} from 'formik';
import moment from 'moment';
import {Trans, useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormError from '../banners/FormError';

import {GUARANTOR, HOUSEHOLD_TYPE, SALUTATION} from '../../utils/constants';
import LanguageSwitchButtons from '../locales/LanguageSwitchButtons';
import ListingCard from '../listings/ListingCard';
import LayoutLandingPage from '../generics/LayoutLandingPage';
import Logo from '../logo/Logo';
import styles from './EnrichingForm.module.css';
import {LogoLeadflowPoweredBy} from '../logo/LogoLeadflowPoweredBy';
import Checkbox from './checkbox/Checkbox'


const PHONE_REGEX = /^\+?\d+$/

LogoLeadflowPoweredBy.propTypes = {s: PropTypes.any};

function EnrichingForm(props) {
    const { data, submitEnrichingData, pets, setPets } = props;

    const { t } = useTranslation();

    const formValidationSchema = Yup.object().shape({
        email: Yup.string().email(t('enriching.form.error.email')).required(t('enriching.form.error.fields')),
        firstName: Yup.string().trim().required(t('enriching.form.error.fields')).max(250, t('enriching.form.firstName.validation.length')).matches(/^[a-zA-ZÀ-ÿ\s-'.]+$/,
            t('enriching.form.firstName.validation.text')),
        gender: Yup.string(),
        birthday: Yup.date().nullable(),
        availableSince: Yup.date().nullable(),
        term: Yup.number(),
        guarantor: Yup.string().default(GUARANTOR.NO_GUARANTOR),
        householdType: Yup.string(),
        household: Yup.string(),
        employment: Yup.string(),
        phone: Yup.string().matches(PHONE_REGEX, t('enriching.form.error.phone')),
        pets: false,
        petType: Yup.string(),
        income: Yup.number(),
        terms: Yup.boolean().default(false).oneOf([true], t('enriching.form.error.fields')).required(t('enriching.form.error.fields')),
        hasAcceptedAgentPrivacyPolicy: Yup.boolean().default(false).oneOf([true], t('enriching.form.error.fields')).required(t('enriching.form.error.fields'))
    });


    const formik= useFormik({
        initialValues: {
            email: '',
            firstName: '',
            gender: 'UNSET',
            birthday: null,
            term: 0,
            availableSince: null,
            guarantor: GUARANTOR.NO_GUARANTOR,
            householdType: '',
            household: '',
            employment: '',
            phone: '',
            pets: false,
            petType: '',
            income: 0,
            terms: false,
            hasAcceptedAgentPrivacyPolicy: false
        },
        validationSchema: formValidationSchema,
        onSubmit: () => {
            return submitEnrichingData(formik.values);
        }
    });

    const { agent, listing } = data.enrichingCandidate;

    return (
        <>
            <LayoutLandingPage>
                <div className={styles.header}>
                    <Logo/>
                    <LanguageSwitchButtons/>
                </div>
                <hr className={styles.divider}/>
                <div className="md:grid md:grid-cols-8 md:gap-4">
                    <div className="md:col-span-3 ">
                        <div className="grid grid-cols-6 gap-2">
                            <div className='col-span-6 space-y-4'>
                                <h3 className='font-bold text-4xl text-primary'>
                                    {t('enriching.title')}
                                </h3>
                                <p className='text-base text-gray-600'>
                                    {t('enriching.body')}
                                </p>
                            </div>
                            <ListingCard agent={agent} listing={listing} className='col-span-6'/>
                        </div>
                    </div>
                    <div className="md:mt-0 md:col-span-5">
                        <form autoComplete='off' onSubmit={formik.handleSubmit} noValidate>
                            <div className="overflow-hidden rounded-lg">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('enriching.form.profile')}</h3>
                                    <div className="grid grid-cols-3 gap-6">
                                        <div className="col-span-3">

                                            <div className="mt-1 flex w-full">
                                                <div className="grid grid-cols-6 gap-2 w-full">
                                                    <div className="col-span-2 sm:col-span-2">
                                                        <div>
                                                            <label htmlFor="company-website" className="block text-sm
                                                                font-medium text-gray-700">
                                                                {t('enriching.form.gender')}
                                                            </label>
                                                            <select
                                                                id="gender"
                                                                name="gender"
                                                                value={formik.values.gender}
                                                                onChange={formik.handleChange}
                                                                className="mt-1 block w-full pl-3 pr-10 py-3 text-base
                                                                    border-gray-300 focus:outline-none focus:ring-primary
                                                                     focus:border-primary sm:text-sm rounded-md"
                                                            >
                                                                <option
                                                                    value={SALUTATION.UNSET}>{t('enriching.form.gender.UNSET')}</option>
                                                                <option
                                                                    value={SALUTATION.MALE}>{t('enriching.form.gender.MALE')}</option>
                                                                <option
                                                                    value={SALUTATION.FEMALE}>{t('enriching.form.gender.FEMALE')}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-span-4'>
                                                        <label htmlFor="company-website" className="block text-sm
                                                font-medium text-gray-700">
                                                            {t('enriching.form.name')}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.firstName}
                                                            placeholder={t('enriching.form.name.placeholder')}
                                                            autoComplete="name"
                                                            className="mt-1 py-3 w-full focus:ring-primary
                                                                focus:border-primary block shadow-sm sm:text-sm
                                                                border-gray-300 rounded-md"
                                                            maxLength="251"
                                                        />
                                                        <FormError message={formik.errors.firstName}/>
                                                    </div>
                                                    <div className="col-span-4 sm:col-span-4 mt-3">
                                                        <label htmlFor="first-name"
                                                            className="block text-sm font-medium text-gray-700">
                                                            {t('enriching.form.birthday')}
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="birthday"
                                                            id="birthday"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.birthday}
                                                            autoComplete="date-of-birth"
                                                            className="mt-1 py-3 focus:ring-primary
                                                                focus:border-primary block w-full shadow-sm
                                                                sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-2 mt-3">
                                                        <label htmlFor="last-name"
                                                            className="block text-sm font-medium text-gray-700">
                                                            {t('enriching.form.yearsOld')}
                                                        </label>
                                                        <input
                                                            disabled
                                                            type="text"
                                                            name="years-old"
                                                            id="years-old"
                                                            autoComplete="age"
                                                            value={formik.values.birthday && formik.values.birthday.length > 1 ?
                                                                moment().diff(formik.values.birthday, 'years') : ''}
                                                            className="mt-1 block py-3 w-full shadow-sm sm:text-sm
                                                                border-gray-300 bg-gray-100 rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid col-span-3 gap-6">
                                            <div className="col-span-4 mt-1">
                                                <label htmlFor="email-address" className="block text-sm font-medium
                                                text-gray-700">
                                                    {t('enriching.form.email')}
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    value={formik.values.email}
                                                    placeholder={t('enriching.form.email.placeholder')}
                                                    onChange={formik.handleChange}
                                                    autoComplete="email"
                                                    className="mt-1 py-3 focus:ring-primary focus:border-primary
                                                     block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                                <FormError message={formik.errors.email}/>
                                            </div>

                                            <div className="col-span-4  mt-1">
                                                <label htmlFor="phone"
                                                    className="block text-sm font-medium text-gray-700">
                                                    {t('enriching.form.phone')}
                                                </label>

                                                <input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    autoComplete="phone"
                                                    className="mt-1 py-3 focus:ring-primary focus:border-primary
                                                    block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />

                                                <FormError message={formik.errors.phone}/>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="overflow-hidden rounded-lg my-4">
                                <div className="px-4 py-4 bg-white sm:p-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('enriching.form.additional')}</h3>
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className='col-span-6'>
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-700 pt-2"
                                            >
                                                {t('enriching.form.employment')}
                                            </label>
                                            <select
                                                required
                                                id="employment"
                                                name="employment"
                                                value={formik.values.employment}
                                                onChange={formik.handleChange}
                                                className="mt-1 py-3 block w-full pl-3 pr-10 text-base border-gray-300
                                                        focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md
                                                        required:invalid:text-gray-500"
                                            >
                                                <option value="" disabled
                                                    hidden>{t('enriching.form.employment.NONE')}</option>
                                                <option
                                                    value="EMPLOYER">{t('enriching.form.employment.EMPLOYED_BY_EMPLOYER')}</option>
                                                <option
                                                    value="ENTREPRENEUR">{t('enriching.form.employment.ENTREPRENEUR')}</option>
                                                <option
                                                    value="STUDENT">{t('enriching.form.employment.STUDENT')}</option>
                                                <option
                                                    value="RETIRED">{t('enriching.form.employment.RETIRED')}</option>
                                                <option
                                                    value="NONE">{t('enriching.form.employment.UNEMPLOYED')}</option>
                                            </select>
                                        </div>

                                        <div className='col-span-6'>
                                            <label
                                                htmlFor="last-name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t('enriching.form.income')}
                                            </label>
                                            <select
                                                required
                                                type="number"
                                                name="income"
                                                id="income"
                                                value={formik.values.income}
                                                onChange={formik.handleChange}
                                                className="mt-1 py-3 focus:ring-primary focus:border-primary
                                                    block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500"
                                            >
                                                <option value="" disabled
                                                    hidden> {t('enriching.form.income.SELECT_INCOME')}</option>
                                                <option value={0}>€ 0 {t('enriching.form.income.perMonth')}</option>
                                                <option value={500}>€ 0 -
                                                    500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={1000}>€ 501 -
                                                    1000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={1500}>€ 1001 -
                                                    1500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={2000}>€ 1501 -
                                                    2000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={2500}>€ 2001 -
                                                    2500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={3000}>€ 2501 -
                                                    3000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={3500}>€ 3001 -
                                                    3500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={4000}>€ 3501 -
                                                    4000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={4500}>€ 4001 -
                                                    4500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={5000}>€ 4501 -
                                                    5000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={5500}>€ 5001 -
                                                    5500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={6000}>€ 5501 -
                                                    6000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={6500}>€ 6001 -
                                                    6500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={7000}>€ 6501 -
                                                    7000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={7500}>€ 7001 -
                                                    7500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={8000}>€ 7501 -
                                                    8000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={8500}>€ 8001 -
                                                    8500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={9000}>€ 8501 -
                                                    9000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={9500}>€ 9001 -
                                                    9500 {t('enriching.form.income.perMonth')}</option>
                                                <option value={10000}>€ 9501 -
                                                    10000 {t('enriching.form.income.perMonth')}</option>
                                                <option value={10001}>€
                                                    10.000+ {t('enriching.form.income.perMonth')}</option>

                                            </select>
                                            <p className="block text-xs font-medium text-gray-500 my-1">
                                                <Trans i18nKey="enriching.form.income.info"/>
                                            </p>
                                        </div>
                                        <div className="col-span-6">
                                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                                {t('enriching.form.guarantor')}
                                            </label>
                                            <select
                                                required
                                                name="guarantor"
                                                id="guarantor"
                                                value={formik.values.guarantor}
                                                onChange={formik.handleChange}
                                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full
                                                    shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500 truncate "
                                            >
                                                <option value={GUARANTOR.NO_GUARANTOR}>
                                                    {t('enriching.form.guarantor.NO_GUARANTOR')}
                                                </option>
                                                <option value={GUARANTOR.IN_NETHERLANDS}>
                                                    {t('enriching.form.guarantor.IN_NETHERLANDS')}
                                                </option>
                                                <option value={GUARANTOR.ABROAD}>
                                                    {t('enriching.form.guarantor.ABROAD')}
                                                </option>
                                            </select>
                                            <p className="block text-xs font-medium text-gray-500 my-1">{t('enriching.form.guarantor.info')}</p>
                                        </div>
                                        <div className="col-span-6">
                                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                                {t('enriching.form.household')}
                                            </label>
                                            <select
                                                required
                                                type="text"
                                                name="householdType"
                                                id="householdType"
                                                value={formik.values.householdType}
                                                onChange={formik.handleChange}
                                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500"
                                            >
                                                <option value="" disabled
                                                    hidden> {t('enriching.form.household.SELECT_HOUSEHOLD')}</option>
                                                <option
                                                    value={HOUSEHOLD_TYPE.SINGLE}>{t('enriching.form.household.SINGLE')}</option>
                                                <option
                                                    value={HOUSEHOLD_TYPE.WITH_PARTNER}>{t('enriching.form.household.WITH_PARTNER')}</option>
                                                <option
                                                    value={HOUSEHOLD_TYPE.WITH_FAMILY}>{t('enriching.form.household.WITH_FAMILY')}</option>
                                                <option
                                                    value={HOUSEHOLD_TYPE.WITH_FRIENDS}>{t('enriching.form.household.WITH_FRIENDS')}</option>
                                            </select>
                                        </div>
                                        <div className="col-span-6">
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t('enriching.form.members')}
                                            </label>
                                            <select
                                                required
                                                type="text"
                                                name="household"
                                                id="household"
                                                value={formik.values.household}
                                                onChange={formik.handleChange}
                                                className="mt-1 py-3 focus:ring-primary focus:border-primary
                                                    block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500"
                                            >
                                                <option value="" disabled
                                                    hidden>{t('enriching.form.household.SELECT_MEMBERS')}</option>
                                                <option value={'1'}>1</option>
                                                <option value={'2'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                                <option value={'5'}>5</option>
                                                <option value={'6'}>6</option>
                                                <option value={'7'}>7</option>
                                                <option value={'8'}>8</option>
                                                <option value={'9'}>9</option>
                                                <option value={'10'}>10</option>

                                            </select>

                                        </div>
                                        <div className='col-span-6'>
                                            <fieldset
                                                onChange={(e) => setPets(JSON.parse(e.target.value))}
                                            >
                                                <div>
                                                    <legend
                                                        className="text-sm font-medium text-gray-900">{t('enriching.form.pet')}</legend>
                                                </div>
                                                <div className="mt-4 space-y-4">
                                                    <div className="flex items-center">
                                                        <input
                                                            id="pets-yes"
                                                            name="pets"
                                                            value={true}
                                                            type="radio"
                                                            className="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                                                        />
                                                        <label
                                                            htmlFor="pets-yes"
                                                            className="ml-3 block text-sm font-medium text-gray-700"
                                                        >
                                                            {t('enriching.form.yes')}
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <input
                                                            id="pets-no"
                                                            value={false}
                                                            name="pets"
                                                            type="radio"
                                                            className="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                                                        />
                                                        <label
                                                            htmlFor="pets-no"
                                                            className="ml-3 block text-sm font-medium text-gray-700"
                                                        >
                                                            {t('enriching.form.no')}
                                                        </label>
                                                    </div>
                                                    {pets === 'true' && (
                                                        <div className="col-span-6 mt-3">
                                                            <label
                                                                htmlFor="petsType"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                Type huisdier
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="petType"
                                                                id="petType"
                                                                value={formik.values.petType}
                                                                onChange={formik.handleChange}
                                                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>
                                                    )}

                                                </div>
                                            </fieldset>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="overflow-hidden rounded-lg my-4">
                                <div className="px-4 py-5 bg-white sm:p-6 space-y-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('enriching.form.rentalInfo')}</h3>

                                    <div className="grid grid-cols-3 gap-6">
                                        <div className="col-span-6">
                                            <label htmlFor="period" className="block text-sm font-medium text-gray-700">
                                                {t('enriching.form.startDate')}
                                            </label>
                                            <input
                                                type="date"
                                                name="availableSince"
                                                id="availableSince"
                                                onChange={formik.handleChange}
                                                value={formik.values.availableSince}
                                                className="mt-1 py-3 focus:ring-primary
                                                                focus:border-primary block w-full shadow-sm
                                                                sm:text-sm border-gray-300 rounded-md"
                                            />

                                        </div>

                                        <div className="col-span-6">
                                            <label htmlFor="period" className="block text-sm font-medium text-gray-700">
                                                {t('enriching.form.period')}
                                            </label>
                                            <select
                                                required
                                                id="term"
                                                name="term"
                                                value={formik.values.term}
                                                onChange={formik.handleChange}
                                                className="mt-1 py-3 block w-full pl-3 pr-10 text-base border-gray-300
                                                        focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md
                                                        required:invalid:text-gray-500"
                                            >
                                                <option value="0">{t('enriching.form.rentalPeriod.UNDEFINED')}</option>
                                                <option value="3">{t('enriching.form.rentalPeriod.ZERO_THREE')}</option>
                                                <option value="6">{t('enriching.form.rentalPeriod.THREE_SIX')}</option>
                                                <option
                                                    value="12">{t('enriching.form.rentalPeriod.SIX_TWELVE')}</option>
                                                <option value="24">{t('enriching.form.rentalPeriod.YEAR')}</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="shadow overflow-hidden rounded-lg">
                                <div className="px-4 py-5 bg-white space-y-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('enriching.form.termsAndAgreements')}</h3>

                                    <div className="grid grid-cols-6 gap-2">
                                        <div className='col-span-6 my-1.5'>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <Checkbox
                                                        id="terms"
                                                        name="terms"
                                                        isChecked={formik.values.terms}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="terms" className="font-medium text-primary">
                                                        {t('enriching.form.info')} {agent.name}*
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-6 my-1.5'>
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <Checkbox
                                                        id="hasAcceptedAgentPrivacyPolicy"
                                                        name="hasAcceptedAgentPrivacyPolicy"
                                                        isChecked={formik.values.hasAcceptedAgentPrivacyPolicy}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm flex">
                                                    <label htmlFor="hasAcceptedAgentPrivacyPolicy"
                                                        className="font-medium text-primary">
                                                        {t('enriching.form.terms')} {agent.details &&
                                                        <a
                                                            target={'_blank'}
                                                            href={agent.details.privacyPolicy}
                                                            className="font-semibold text-primary underline"
                                                            rel="noreferrer"
                                                        >
                                                            {t('enriching.form.privacy')}
                                                        </a>}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-span-6 my-3'>
                                <button
                                    disabled={!formik.isValid || !formik.dirty}
                                    type="submit"
                                    className={`
                                        inline-flex ml-auto items-center px-5 py-2.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md
                                        text-gray-700 focus:outline-nonefocus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary
                                        ${!formik.isValid || !formik.dirty ? 'bg-white text-gray-900' : 'bg-primary text-white'}
                                    `}
                                >
                                    {t('enriching.form.submit')}
                                </button>
                            </div>
                            {!formik.isValid && <FormError message={t('enriching.form.error.general')}/>}
                        </form>

                        <hr className={styles.divider}/>
                        <LogoLeadflowPoweredBy/>
                    </div>
                </div>
            </LayoutLandingPage>
        </>
    );
}

EnrichingForm.propTypes = {
    submitEnrichingData: PropTypes.func,
    data: PropTypes.object,
    pets: PropTypes.bool,
    setPets: PropTypes.func
};

export default EnrichingForm;
