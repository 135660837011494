import ModalModel from '../../../../modal/logic/models/modalModel';
import ModalButtonModel from '../../../../modal/logic/models/modalButtonModel';
import AssignTenantModalActionType from './assignTenantModalActionType';
import AssignTenantModalFragmentEnum from './AssignTenantModalFragmentEnum';
import CandidateModel from '../../../../../models/candidate/candidateModel';

export default class AssignTenantModalModel implements ModalModel<AssignTenantModalActionType> {

    private constructor(
        public readonly icon: string,
        public readonly isOpen: boolean,
        public readonly isLoading: boolean,
        public readonly candidate: CandidateModel,
        public readonly currentFragment: AssignTenantModalFragmentEnum,
        public readonly buttons: ReadonlyArray<ModalButtonModel<AssignTenantModalActionType>>
    ) {}

    public static createEmpty(): AssignTenantModalModel {
        return new AssignTenantModalModel(
            undefined,
            false,
            false,
            null,
            AssignTenantModalFragmentEnum.AssignTenant,
            []
        )
    }

    public static createWithAssignTenantContent(candidate: CandidateModel): AssignTenantModalModel {
        return new AssignTenantModalModel(
            'ri-user-star-line',
            true,
            false,
            candidate,
            AssignTenantModalFragmentEnum.AssignTenant,
            [
                new ModalButtonModel('actions.cancel', false, AssignTenantModalActionType.Cancel),
                new ModalButtonModel('candidate.assignTenant.model.button.assign', true, AssignTenantModalActionType.Assign, false, false),
                new ModalButtonModel('candidate.assignTenant.model.button.assignAndArchive', true, AssignTenantModalActionType.AssignAndArchive, false, true),
            ]
        )
    }

    public withIsLoading(val: boolean): AssignTenantModalModel {
        return new AssignTenantModalModel(this.icon, this.isOpen, val, this.candidate, this.currentFragment, this.buttons)
    }

    public withIsOpen(val: boolean): AssignTenantModalModel {
        return new AssignTenantModalModel(this.icon, val, this.isLoading, this.candidate, this.currentFragment, this.buttons)
    }
    public enableAssignAndArchiveButton() {
        const newButtons = this.withIsAssignAndArchiveButtonDisabled(false)
        return new AssignTenantModalModel(this.icon, this.isOpen, this.isLoading, this.candidate, this.currentFragment, newButtons)
    }

    public disableAssignAndArchiveButton() {
        const newButtons = this.withIsAssignAndArchiveButtonDisabled(true)
        return new AssignTenantModalModel(this.icon, this.isOpen, this.isLoading, this.candidate, this.currentFragment,  newButtons)
    }

    private withIsAssignAndArchiveButtonDisabled(val: boolean): ModalButtonModel<AssignTenantModalActionType>[] {
        return this.buttons.map(b => {
            if (b.actionType === AssignTenantModalActionType.Assign) {
                return b.withIsHidden(!val)
            }
            if (b.actionType === AssignTenantModalActionType.AssignAndArchive) {
                return b.withIsHidden(val)
            }
            return b
        })
    }
}
