import {createContext} from 'react';
import {LogoutOptions, PopupConfigOptions, PopupLoginOptions} from '@auth0/auth0-spa-js'

interface TestAuth0ProviderProps {
    readonly children: React.ReactNode
}

export const TestAuth0Context = createContext(null)

export default function TestAuth0Provider(props: TestAuth0ProviderProps) {
    return (
        <TestAuth0Context.Provider value={
            {
                isAuthenticated: false,
                isLoading: false,
                error: null,
                user: {
                    email: 'johnny.test@e2e.com',
                    picture: 'https://cdn.auth0.com/avatars/t.png',
                    imageUrl: 'https://cdn.auth0.com/avatars/t.png'
                },

                loginWithPopup: () => {
                    this.isAuthenticated = true
                },

                logout: () => {
                    this.isAuthenticated = false
                }
            }
        }>
            {props.children}
        </TestAuth0Context.Provider>
    )
}
