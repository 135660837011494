import { TrashIcon, PlusCircleIcon, ShieldExclamationIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import Alert from '../alert/Alert'
import Button from '../buttons/button/Button'
import ButtonType from '../buttons/button/logic/models/buttonType'

export interface MultipleEntrySelectorProps {
    readonly list: string[]
    readonly textBoxPlaceholder: string
    readonly validationFn: (val: string) => { success: boolean, message: string }
    readonly onChange?: (val: string[]) => void
    readonly onValidationPass?: (val: string) => void
    readonly onValidationFail?: (val: string) => void
}

function MultipleEntrySelector(props: MultipleEntrySelectorProps) {
    const [entry, setEntry] = useState('')
    const [formError, setFormError] = useState('')

    function addNewEntry() {
        if (entry === undefined || entry === '') {
            setFormError('Kan geen lege waarde toevoegen.')
            return
        }

        if (props.list.includes(entry)) {
            setFormError('Record al toegevoegd.')
            return
        }

        if (props.validationFn && !props.validationFn(entry).success) {
            setFormError(props.validationFn(entry).message)
            return
        }

        props.onChange?.([...props.list, entry.trim()])
        setEntry('')
        setFormError('')
    }

    function removeEntry(entryObj) {
        const index = props.list.indexOf(entryObj)
        const newList = [...props.list]
        if (index > -1) {
            newList.splice(index, 1)
        }
        props.onChange?.(newList)
    }

    function handleOnChange(val: string): void {
        setEntry(() => {
            const isValid = props.validationFn && props.validationFn(val).success
            setFormError(!isValid ? props.validationFn(val).message : '')
            if (isValid && props.onValidationPass) {
                props.onValidationPass(val)
            } else {
                props.onValidationFail?.(val)
            }
            return val
        })
    }

    return (
        <>
            <div className="border-grey-400 rounded-md border-2 p-4">
                <ul className="">
                    {props.list &&
                        props.list.map((listItem) => (
                            <li
                                className="flex items-center justify-between bg-gray-200 py-3 px-4 my-2 rounded-md"
                                key={listItem}>
                                <p>{listItem}</p>
                                <button
                                    type="button"
                                    onClick={() => {
                                        removeEntry(listItem)
                                    }}>
                                    <TrashIcon className="h-6 w-6 text-gray-500" />
                                </button>
                            </li>
                        ))}
                </ul>
                {(!props.list || !props.list.length) && (
                    <div className="rounded-md bg-grey-50 p-4 mt-4 border-grey-100 border-2">
                        <div className="flex">
                            <ShieldExclamationIcon className="h-5 w-5 text-grey-400 " aria-hidden="true" />
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-grey-800">
                                    Er is nog geen item toegevoegd.
                                </h3>
                            </div>
                        </div>
                    </div>
                )}

                <div className="mt-5 flex flex-col md:grid md:grid-cols-3 lg:grid-cols-4 md:gap-2 gap-y-2">
                    <input
                        type="text"
                        name="text"
                        id="email"
                        value={entry}
                        className="md:col-span-2 lg:col-span-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                        placeholder={props.textBoxPlaceholder || 'Mijn item'}
                        onChange={(e) => handleOnChange(e.target.value)}
                    />
                    <Button
                        buttonType={ButtonType.Primary}
                        title="Toevoegen"
                        icon="ri-add-circle-line"
                        onClick={addNewEntry}
                    />
                </div>

                {formError && <Alert message={formError} />}
            </div>
        </>
    )
}

export default MultipleEntrySelector
