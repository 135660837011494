import React from 'react';
import PropTypes from 'prop-types';
import CandidateStatus from '../../enums/candidateStatus';
import CandidateFilterStatus from '../../enums/candidateFilterStatus';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function ListingsBucket(props) {
    const { selectStatus, status,
        numberOfLeads, numberOfUnassessedLeads,
        numberOfSuitableLeads, numberOfLeadsToAssess,
        numberOfRejectedLeads, numberOfNotSuitableLeads } = props;

    const steps = [
        { id: CandidateStatus.None, name: 'Nieuw', count: numberOfUnassessedLeads },
        { id: CandidateStatus.Assessment, name: 'Te Beoordelen', count: numberOfLeadsToAssess },
        { id: CandidateStatus.Suitable, name: 'Goedgekeurd', count: numberOfSuitableLeads },
        { id: CandidateStatus.NotSuitable, name: 'Wachtlijst', count: numberOfNotSuitableLeads },
        { id: CandidateStatus.Rejected, name: 'Afgewezen', count: numberOfRejectedLeads },
        { id: CandidateFilterStatus.All, name: 'Alles', count: numberOfLeads }
    ]

    return (
        <>
            <nav aria-label="Progress" className=" mx-auto w-full z-0 bg-white">
                <ol
                    role="list"
                    className="border border-gray-300 rounded-lg overflow-hidden  md:flex md:divide-y-0"
                >
                    {steps.map((step, stepIdx) => (
                        <li key={step.id}
                            className={classNames(
                                'relative md:flex-1 md:flex py-4 flex justify-center',
                                status === step.id ? 'bg-success-100' : 'bg-white cursor-pointer',
                                'border-r',
                                stepIdx !== steps.length - 1 && 'border-gray-300',
                                stepIdx === 0 && 'rounded-l-lg',
                                stepIdx === steps.length - 1 && 'rounded-r border-transparent'
                            )} style={{zIndex: 4}} onClick={() => selectStatus(step.id) }>
                            <div>
                                <span className={classNames(
                                    'pl-8 text-base font-medium',
                                    status === step.id ? 'text-primary font-semibold' : 'text-gray-500 cursor-pointer'
                                )}>
                                    {step.name} <span className={classNames(
                                        'inline-flex items-center px-1.5 py-0.5 rounded-full text-sm font-medium mr-5',
                                        status === step.id ? 'bg-success-300 text-primary-700' : 'bg-gray-100 text-gray-800',
                                        'ml-1'
                                    )}>
                                        {step.count}
                                    </span>
                                </span>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base
          border-gray-300 focus:outline-none focus:ring-secondary 
          focus:border-secondary sm:text-sm rounded-md"
                    defaultValue={steps.find(tab => tab.id).id}
                >
                    {steps.map(tab => (
                        <option key={tab.id}>{tab.name}</option>
                    ))}
                </select>
            </div>
        </>
    );
}

ListingsBucket.propTypes = {
    status: PropTypes.string,
    selectStatus: PropTypes.func,
    numberOfLeads: PropTypes.number,
    numberOfLeadsToAssess: PropTypes.number,
    numberOfNotSuitableLeads: PropTypes.number,
    numberOfSuitableLeads: PropTypes.number,
    numberOfRejectedLeads: PropTypes.number,
    numberOfUnassessedLeads: PropTypes.number
};

export default ListingsBucket;
