import { request } from '../../../utils/api';
import { API_URIS } from '../../../utils/constants';

export const getAllAgents = (token, params) => (dispatch) => {
    request(token)
        .get(API_URIS.AGENTS_URI, { params: params })
        .then((response) => {
            dispatch({
                type: 'SET_AGENTS',
                payload: response.data,
            });
        })
        .catch((e) => console.log(e));
};
