import React, { RefObject, useState } from 'react'
import styles from './SaveableInput.module.css'
import ScreeningModalServiceInterface from '../../modals/screeningModal/service/screeningModalServiceInterface'
import SaveableInputModel from './SaveableInputModel'

interface SaveableInputProps {
    readonly listingId: string
    readonly value: string
    readonly onFocus?: () => void
    readonly onBlur?: () => void
    readonly service: ScreeningModalServiceInterface
}

export default function SaveableInput(props: SaveableInputProps) {
    const [model, setModel] = useState(new SaveableInputModel(props.value, props.value, false))
    const inputElement: RefObject<HTMLInputElement> = React.createRef()

    function handleOnEdit() {
        inputElement.current.focus()
    }

    function handleSaveIconMouseDown(e) {
        e.preventDefault()
        setModel(prev => prev.withSaving(true))
    }

    async function handleSaveIconClick(e) {
        inputElement.current.blur()

        try {
            setModel(prev => prev.startLoading())

            await props.service.updateTransactionalListingPrice(props.listingId, model.value)

            props.service.updateTransactionalListingPriceSuccess()
            setModel(prev => prev.finishLoadingSuccess())
        } catch(e) {
            props.service.updateTransactionalListingPriceFailed()
            setModel(prev => prev.finishLoadingFailed())
        }
        finally {
            setModel(prev => prev.withSaving(false))
        }
    }

    function blur() {
        if (!model.isSaving) {
            setModel(prev => prev.withValue(prev.defaultValue))
        }

        if (props.onBlur !== undefined) {
            props.onBlur()
        }
    }

    function focus() {
        if (props.onFocus !== undefined) {
            props.onFocus()
        }
    }

    return <>
        <div className={styles.SaveableInput}>
            <input
                ref={inputElement}
                type="number"
                className={styles.input}
                defaultValue={model.defaultValue}
                value={model.value}
                onBlur={blur}
                onFocus={focus}
                onChange={(e) => setModel(prev => prev.withValue(e.target.value))}
            />
            <i
                className={styles.euroIcon + ` ri-money-euro-circle-line`}
            ></i>
            <div
                className={styles.editAndSaveIcon}
            >
                <i
                    className={styles.editIcon + ` ri-edit-2-line`}
                    onClick={() => handleOnEdit()}
                ></i>
                <i
                    className={styles.saveIcon + ` ri-save-3-line`}
                    onClick={handleSaveIconClick}
                    onMouseDown={handleSaveIconMouseDown}
                ></i>
            </div>
        </div>
    </>
}
