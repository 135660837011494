import { GrowthBook } from '@growthbook/growthbook-react';

export const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: 'sdk-DXDfry4B75FIrjr',
    enableDevMode: (window as any)?.NODE_ENV === 'development' ? true : false,
    trackingCallback: (experiment, result) => {
        const cookie_consent = getGA4CookiebotConsent();

        if (!cookie_consent) {
            return;
        }

        (window as any).dataLayer.push({
            'event': 'experiment_viewed',
            'event_category': 'experiment',
            'experiment_id': experiment.key,
            'variation_id': result.key,
            'consent': cookie_consent
        });
    }
});

export function getGA4CookiebotConsent() {
    const Cookiebot = (window as any).Cookiebot;

    if (!Cookiebot || !Cookiebot.consent) {
        return null;
    }

    return {
        'ad_storage': Cookiebot.consent.marketing ? 'granted' : 'denied',
        'ad_personalization': Cookiebot.consent.marketing ? 'granted' : 'denied',
        'ad_user_data': Cookiebot.consent.preferences ? 'granted' : 'denied',
        'analytics_storage': Cookiebot.consent.statistics ? 'granted' : 'denied'
    }
}
