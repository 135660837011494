import AnnouncementPageFrame from '../../components/announcementPageFrame/AnnouncementPageFrame';
import SuccessPageFragment from './fragments/success/SuccessPageFragment';
import ErrorPageFragment from '../../components/errorPageFragment/ErrorPageFragment';
import {useEffect, useState} from 'react';
import api from '../../utils/api';
import {useParams} from 'react-router-dom';
import LoadStateEnum from '../../enums/loadStateEnum';
import Loading from '../../components/empty-states/Loading';

export default function MarketingMessagesSubscribePage() {
    const [loadState, setLoadState] = useState(LoadStateEnum.Loading);
    const {id, listingCity} = useParams()

    useEffect(() => {
        api.postWithoutAuth(
            'api/subscribe-homeseeker-to-marketing-messages',
            {homeseekerId: id}
        )
            .then(() => setLoadState(LoadStateEnum.Success))
            .catch(() => setLoadState(LoadStateEnum.Error))
    }, [])

    function renderPageFragment() {
        if (loadState === LoadStateEnum.Success) {
            return <SuccessPageFragment listingCity={listingCity}/>
        }
        return <ErrorPageFragment/>
    }

    return (
        <>
            {loadState === LoadStateEnum.Loading ?
                <Loading/> :
                <AnnouncementPageFrame>
                    {renderPageFragment()}
                </AnnouncementPageFrame>
            }
        </>
    )
}
