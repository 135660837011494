import CellRendererInterface from '../../table/cellRenderers/cellRendererInterface';
import CandidateStatusButtonModel from '../components/candidateStatusButton/logic/models/candidateStatusButtonModel';
import CandidateStatusButton from '../components/candidateStatusButton/CandidateStatusButton';

export default class CandidateStatusButtonCellRenderer implements CellRendererInterface<CandidateStatusButtonModel> {
    constructor(
        readonly model: CandidateStatusButtonModel) {
    }

    public render(): any {
        return <CandidateStatusButton model={this.model} />
    }
}
